<template>
  <div>
    <ion-header translucent>
      <ion-toolbar>
        <ion-title>
          {{ title }}
        </ion-title>
        <ion-icon 
          v-if="hasCloseListener" 
          class="mr-3" 
          slot="end" 
          size="large" 
          name="close" 
          color="moto"
          @click="$emit('close')" 
        />
      </ion-toolbar>
      <slot name="header" />
    </ion-header>
    <slot/>
  </div>
</template>

<style scoped>
  ion-toolbar ion-icon {
    cursor: pointer;
  }
</style>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    hasCloseListener(){
      return this.$listeners && this.$listeners.close;
    }
  }
}
</script>