import { GET_API_VERSION, SHOULD_UPDATE_ACTION } from "../store-actions";

import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { SET_API_VERSION, SET_APP_SHOULD_UPDATE, SET_MAINTENANCE_MODE } from "../store-mutations";
import { APP_DEFAULTS, FEATURE_FLAG_MAPPING } from "../../configs";
import router from "../../router";
import { getOptimizelyClient } from "../../services/optimizely.service";
import {
    CURRENT_USER_GETTER,
    GET_APP_SHOULD_UPDATE,
    OPTIMIZELY_IS_READY,
    GET_APP_VERSION,
    GET_MAINTENANCE_MODE
} from "@/store/store-getters";
import { gte as semverGte, coerce } from "semver";
import axios from 'axios';
import { captureException } from "@/utils";

export default {
    state: {
        shouldUpdate: false,
        maintenanceMode: false,
        api_version: {
            api_version: null,
            minimum_supported_client_version: null,
            minimum_supported_native_version: {
                ios: null,
                android: null
            },
        }
    },
    mutations: {
        [SET_APP_SHOULD_UPDATE](state, shouldUpdate) {
            state.shouldUpdate = shouldUpdate;
        },
        [SET_API_VERSION](state, apiVersionResponse) {
            state.api_version = apiVersionResponse;
        },
        [SET_MAINTENANCE_MODE](state, maintenanceMode) {
            state.maintenanceMode = maintenanceMode;
        },

    },
    getters: {
        [GET_APP_SHOULD_UPDATE]: state => {
            return state.shouldUpdate;
        },
        [GET_MAINTENANCE_MODE]: state => {
            return state.maintenanceMode;
        }
    },
    actions: {
        async [GET_API_VERSION]({ commit }) {
          try {
              const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/version`);
              commit(SET_API_VERSION, response.data);
              commit(SET_MAINTENANCE_MODE, false);
          } catch (error) {
              // If the application is in maintenance mode we will receive a 503 response, and display a custom dialog
              if (error.response) {
                  if (error.response.status === 503) {
                      commit(SET_MAINTENANCE_MODE, true)
                      return;
                  }
              }
              // We do not allow network errors on this endpoint to block the application
              captureException(error);
          }
        },
        async [SHOULD_UPDATE_ACTION]({ dispatch, commit, state, rootGetters }) {
            if (!Capacitor.isNativePlatform()) {
                return;
            }

            const optimizely = getOptimizelyClient();

            const currentUser = rootGetters[CURRENT_USER_GETTER];
            const currentUserId = currentUser?.id ? currentUser.id.toString() : '';

            // Sorry, optimizely needs to load first...
            if (!rootGetters[OPTIMIZELY_IS_READY] || !optimizely.isFeatureEnabled(FEATURE_FLAG_MAPPING.FORCE_UPDATE, currentUserId)) {
                return;
            }

            if (router.currentRoute?.meta?.allowAutoReloads === false) {
                return;
            }

            const appInfo = await App.getInfo()
            const nativeAppVersion = appInfo.version || '1.0';
            const clientAppVersion = rootGetters[GET_APP_VERSION]

            if (!state.api_version.api_version) {
                await dispatch(GET_API_VERSION)
            }

            const shouldUpdateNativeApp = !javascriptSupportsNativeAppVersion(nativeAppVersion)
              || !apiSupportsNativeAppVersion(nativeAppVersion, state)
              || !apiSupportsClientAppVersion(clientAppVersion, state);

            commit(SET_APP_SHOULD_UPDATE, shouldUpdateNativeApp);
        }
    }
}

/**
 * Checks if the javascript internal version is compatible with the core native app version
 *
 * @param nativeAppVersion
 * @returns {boolean}
 */
function javascriptSupportsNativeAppVersion(nativeAppVersion) {
    const minimumVersion = Capacitor.getPlatform() === 'ios' ?
      APP_DEFAULTS.MINIMUM_SUPPORTED_NATIVE_VERSION.IOS :
      APP_DEFAULTS.MINIMUM_SUPPORTED_NATIVE_VERSION.ANDROID;

    // App is oke if the minimum supported native app version (1.0) <= current native app version (2.11.12)
    const supported = semverGte(coerce(nativeAppVersion).version, coerce(minimumVersion).version);

    if (!supported) {
        /* eslint-disable-next-line no-console */
        console.warn(`Native version of app ${nativeAppVersion} is not compatible with minimum version ${minimumVersion}`);
    }

   return supported;
}

/**
 * Checks if the api supports the minimum native app version
 *
 * @param nativeAppVersion
 * @param state
 * @returns {boolean}
 */
function apiSupportsNativeAppVersion(nativeAppVersion, state) {
    const minimumVersion = Capacitor.getPlatform() === 'ios' ?
      state.api_version.minimum_supported_native_version.ios :
      state.api_version.minimum_supported_native_version.android;

    if (!minimumVersion) {
        // Unknown state, we will let it pass
        return true;
    }

    // App is oke if the minimum supported native app version (1.0) <= current native app version (2.11.12)
    const supported = semverGte(coerce(nativeAppVersion).version, coerce(minimumVersion).version);

    if (!supported) {
        /* eslint-disable-next-line no-console */
        console.warn(`Native version of app ${nativeAppVersion} is not compatible with required minimum api version ${minimumVersion}`);
    }

    return supported;

}

/**
 * Checks if the api supports the minimum client app version
 *
 * @param clientAppVersion
 * @param state
 * @returns {boolean}
 */
function apiSupportsClientAppVersion(clientAppVersion, state) {
    const minimumVersion = state.api_version.minimum_supported_client_version;

    if (!minimumVersion || !clientAppVersion) {
        // Unknown state, we will let it pass
        return true;
    }

    // App is oke if the minimum supported native app version (1.0) <= current native app version (2.11.12)
    const supported = semverGte(coerce(clientAppVersion).version, coerce(minimumVersion).version);

    if (!supported) {
        /* eslint-disable-next-line no-console */
        console.warn(`Client version of app ${clientAppVersion} is not compatible with required minimum api version ${minimumVersion}`);
    }

    return supported;
}