export const loadingSpinner = 'crescent';

export const APP_ENV = process.env.VUE_APP_ENV || 'production';

export const APP_DEFAULTS = {
  CONTACT_EMAIL: 'info@motoinside.app',
  CONTACT_PHONE: '+31 (0) 651 858 356',
  MAX_TICKETS_PER_TICKET : 1, // Default maximum number of tickets when creating a ticket for an event
  DEFAULT_TIMEZONE: 'Europe/Amsterdam', // The timezone that is used to reference local time differences
  MINIMUM_SUPPORTED_NATIVE_VERSION: {
    IOS: process.env.VUE_APP_MINIMUM_SUPPORTED_NATIVE_VERSION_IOS || '1.0', // The minimum supported native version of the ios app
    ANDROID: process.env.VUE_APP_MINIMUM_SUPPORTED_NATIVE_VERSION_ANDROID || '1.0' // The minimum supported native version of the android app
  },
  AD_SPEED: process.env.AD_SPEED || 10000,
  AD_THROTTLE: process.env.AD_THROTTLE || 2000,
}
