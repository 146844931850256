import Vue from 'vue';

import Error from '@/components/Error.vue'
import LoadingButton from '@/components/LoadingButton.vue';
import Button from '@/components/buttons/Button.vue';
import InfiniteScroll from '@/components/InfiniteScroll.vue';
import { SkeletonList, SkeletonCards } from '@/components/skeletons';
import Refresher from '@/components/Refresher.vue';
import { ContentContainer } from '@/components/containers';
import GoBackHeader from '@/components/navigation/GoBackHeader';
import List from '@/components/List';

export const registerComponents = () => {
  Vue.component('Error', Error);
  Vue.component('LoadingButton', LoadingButton);
  Vue.component('Button', Button);
  Vue.component('InfiniteScroll', InfiniteScroll);
  Vue.component('SkeletonList', SkeletonList);
  Vue.component('SkeletonCards', SkeletonCards);
  Vue.component('Refresher', Refresher);
  Vue.component('ContentContainer', ContentContainer);
  Vue.component('GoBackHeader', GoBackHeader);
  Vue.component('List', List);
}