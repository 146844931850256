export const StripeStatus = {
    paid: 'paid',
    cancelled: 'cancelled',
    refunded: 'refunded',
    partially_refunded: 'partially_refunded',
    open: 'open'
}

export const REQUIREMENT_BETWEEN_AGE = 'BETWEEN_AGE';

export const OrderableRequirementType = {
    [REQUIREMENT_BETWEEN_AGE]: REQUIREMENT_BETWEEN_AGE
}

export const EmailAvailableEnum = {
    exists: 'exists',
    exists_tfa: 'exists_tfa',
    unverified: 'unverified',
    available: 'available',
}

export const EventTypeEnum = {
    TRAINING: 'TRAINING',
    COMPETITION: 'COMPETITION',
    OTHER: 'OTHER',
}


export const AdEventType = {
    CLICK: 'click',
    IMPRESSION: 'impression',
}

export const RecentSearchResultType = {
    NEWS: 'NEWS',
    FACILITY: 'FACILITY',
    EVENT: 'EVENT',
}
