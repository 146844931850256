<template>
  <ion-refresher slot="fixed" @ionRefresh="($event) => this.$emit('refresh', $event)">
    <ion-refresher-content :refreshing-spinner="loadingSpinner" />
  </ion-refresher>
</template>

<script>
import { loadingSpinner } from '@/configs';

export default {
  emits: ['refresh'],
  data() {
    return {
      loadingSpinner
    }
  }
}
</script>