import { RECORD_AD_EVENT } from "../../graphql/mutations";
import { CURRENT_USER_GETTER } from "../store-getters";
import { RECORD_AD_EVENT_ACTION } from "../store-actions";
import { apolloClient } from "../../vue-apollo";

export default {

  state: {
  },

  getters: {

  },

  mutations: {

  },

  actions: {

    [RECORD_AD_EVENT_ACTION]({rootGetters}, {ad_uuid, event_type}) {
      return apolloClient.mutate({
        mutation: RECORD_AD_EVENT,
        variables: {
          user_id: rootGetters[CURRENT_USER_GETTER]?.id,
          ad_uuid,
          event_type
        },
      })
    },

  },

}