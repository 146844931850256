import * as mutations  from "@/store/store-mutations";
import * as getters from "@/store/store-getters";
import * as actions from "@/store/store-actions";
import router from "@/router";

export default {
  state: {
    appVersion: null,
    appIsActive: false,
    newVersionAvailable: false,
    appShouldReload: false,
    serviceWorker: null,
  },
  mutations: {
    [mutations.SET_APP_IS_ACTIVE]: (state, appIsActive) => state.appIsActive = appIsActive,
    [mutations.SET_NEW_VERSION_AVAILABLE]: (state, newVersionAvailable) => state.newVersionAvailable = newVersionAvailable,
    [mutations.SET_APP_SHOULD_RELOAD]: (state, appShouldReload) => state.appShouldReload = appShouldReload,
    [mutations.SET_SERVICE_WORKER]: (state, serviceWorker) => state.serviceWorker = serviceWorker,
    [mutations.SET_APP_VERSION]: (state, appVersion) => state.appVersion = appVersion,
  },
  getters: {
    [getters.GET_APP_IS_ACTIVE]: (state) => state.appIsActive,
    [getters.GET_NEW_VERSION_AVAILABLE]: (state) => state.newVersionAvailable,
    [getters.GET_APP_SHOULD_RELOAD]: (state) => state.appShouldReload,
    [getters.GET_APP_IS_ACTIVE]: (state) => state.serviceWorker,
    [getters.GET_APP_VERSION]: (state) => state.appVersion,
  },
  actions: {

    [actions.SET_APP_IS_ACTIVE]({commit, dispatch}, appIsActive) {
      commit('setAppIsActive', appIsActive);

      if (appIsActive) {
        // Always re-check our access token expiration date
        dispatch(actions.REFRESH_EXPIRED_ACCESS_TOKEN_ACTION);

        // We will also check if our serviceworker has updated
        dispatch(actions.CHECK_SERVICE_WORKER_UPDATES);

        // @todo: How can our app know this?
        if (window.location.hostname.includes('stripe')) {
          router.push({path: '/'});
          return;
        }
      }

    },

    [actions.CHECK_SERVICE_WORKER_UPDATES]({state}) {
      if (state.serviceWorker) {
        state.serviceWorker.update();
      }
    },

    [actions.SET_NEW_VERSION_AVAILABLE]({commit}) {
      commit(mutations.SET_NEW_VERSION_AVAILABLE, true);

      if (router.currentRoute?.meta?.allowAutoReloads === false) {
        return;
      }

      window.location.reload();
    },

    [actions.SET_APP_SHOULD_RELOAD]({commit}) {
      commit(mutations.SET_APP_SHOULD_RELOAD, true);
    },

    [actions.SET_SERVICE_WORKER]({commit}, serviceWorker) {
      commit(mutations.SET_SERVICE_WORKER, serviceWorker);
    }

  }
}
