import OneSignal from 'onesignal-cordova-plugin';

export const PushNotificationsService = {
    init() {
        document.addEventListener('deviceready', this.initOneSignal, false);
    },

    initOneSignal() {
        // Onesignal APP ID is not set. We can not proceed without it
        if (!process.env.VUE_APP_ONESIGNAL_APP_ID) {
            // eslint-disable-next-line no-console
            console.warn('Onesignal App ID is not configured. Push notifications are not available.')
            return;
        }

        OneSignal.initialize(process.env.VUE_APP_ONESIGNAL_APP_ID);
        // let myClickListener = async function(event) {
        //     // let notificationData = JSON.stringify(event);
        //     // console.log(notificationData);
        // };
        // OneSignal.Notifications.addEventListener("click", myClickListener);
      
        // Prompts the user for notification permissions.
        //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
        // OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
        //     // console.log("User accepted notifications: " + accepted);
        // });
    }
}