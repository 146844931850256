<template>
  <ion-list ref="sliding" lines="full">
    <ion-list-header v-if="header" :color="headerColor && headerColor">
      <ion-label>{{ header }}</ion-label>
      <slot name="header" />
    </ion-list-header>
    <slot name="top" />

    <template v-if="$slots.default && !loading">
      <slot />
    </template>
    <ion-item v-if="!$slots.default && !loading">
      <ion-label text-wrap>
        {{ notFoundMessage }}
      </ion-label>
    </ion-item>

    <SkeletonList v-if="loading" />

    <InfiniteScroll v-if="$slots.default && loadingMore" @ionInfinite="$event => {this.$emit('loadMore', $event)}" />
  </ion-list>
</template>

<script>
export default {
  props: {
    notFoundMessage: {
      type: String,
    },
    headerColor: {
      type: String,
    },
    header: {
      type: String,
    },
    loadingMore: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  methods: {
    closeSlidingItems() {
      this.$refs.sliding.closeSlidingItems();
    }
  }
}
</script>