import policies from './index';

export default class Gate {

    constructor(user) {
        this.user = user;

        this.policies = policies
    }

    setUser(user) {
        this.user = user;

        return this;
    }

    can(action, type, model = null, args = null) {
        if (!this.policies[type]?.[action]) {
            // eslint-disable-next-line no-console
            console.warn(`Policy ${type}.${action} is not defined`);
            return false;
        }

        return this.policies[type][action](this.user, model, args)
    }
}
