import Vue from 'vue'
import Vuex from 'vuex'
import AuthModule from './modules/AuthModule';
import PlacesModule from "./modules/PlacesModule";
import CheckinModule from './modules/CheckinModule';
import StateChangeModule from './modules/StateChangeModule';
import BuyTicketsModule from "@/store/modules/BuyTicketsModule";
import UpdateAppModule from './modules/UpdateAppModule';
import OptimizelyModule from "@/store/modules/OptimizelyModule";
import AdModule from "@/store/modules/AdModule";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    ad: AdModule,
    updateApp: UpdateAppModule,
    auth: AuthModule,
    places: PlacesModule,
    checkin: CheckinModule,
    stateChange: StateChangeModule,
    optimizely: OptimizelyModule,
    buyTickets: BuyTicketsModule,
  }
})
