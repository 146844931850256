import EventsPolicy from "@/policies/EventsPolicy";
import FacilitiesPolicy from "@/policies/FacilitiesPolicy";
import TrainingEventRegistrationsPolicy from "@/policies/TrainingEventRegistrationsPolicy";
import NewsPolicy from "@/policies/NewsPolicy";

export default {
  news: NewsPolicy,
  events: EventsPolicy,
  facilities: FacilitiesPolicy,
  training_event_registrations: TrainingEventRegistrationsPolicy,
}
