import Optimizely, { enums } from '@optimizely/optimizely-sdk';
import store from "@/store";
import { SET_OPTIMIZELY_IS_READY } from "@/store/store-mutations";

let optimizely = null;

/**
 * @returns {Optimizely.Client} 
 */
export const getOptimizelyClient = () => {
    if (optimizely) {
        return optimizely;
    }

    optimizely = Optimizely.createInstance({
        sdkKey: process.env.VUE_APP_OPTIMIZELY_SDK,
        logLevel: enums.LOG_LEVEL.ERROR,
    });

    optimizely.onReady().then(() => {
        store.commit(SET_OPTIMIZELY_IS_READY);
    });

    return optimizely;
}