<template>
  <div>
    <div class="moto-error" v-if="validationErrors.length">
      <div
        v-for="(validationMessages, i) in validationErrors"
        :key="i"
      >
        <div v-for="(validationMessage, j) in validationMessages" :key="j">
          <ion-icon name="alert"></ion-icon> {{ validationMessage }}
        </div>
      </div>
    </div>

    <div class="moto-error" v-if="errorMessage">
      <ion-icon name="alert"></ion-icon> {{ errorMessage }}
    </div>
  </div>
</template>

<script>

  import { ValidationError } from "@/utils/validation";

export default {
  props: ["error"],
  computed: {
    errorMessage() {
      if(!this.error) {
        return "";
      }

      if (this.error instanceof ValidationError) {
        return "";
      }

      if (this.error?.graphQLErrors?.[0]?.extensions?.category === "validation") {
        return "";
      }

      if(typeof this.error === 'string') {
        return this.error;
      }

      this.$captureException(this.error);
      return this.error.message.replace("GraphQL error:", "").trim();
    },
    validationErrors() {
        if (this.error?.graphQLErrors?.[0]?.extensions?.category === "validation") {
            return Object.entries(this.error.graphQLErrors[0].extensions.validation).map(validationMessages => {
              return validationMessages[1].map( (validationMessage) => {
                return validationMessage.replace('data.', '');
              })
            })
        }

        if (this.error instanceof ValidationError) {
          return Object.entries(this.error.errors).map(validationError => {
            return validationError[1].map( (validationMessage) => {
              return validationMessage.replace('data.', '');
            })
          })
        }

        return []
    }
  },
};
</script>

<style scoped>
  .moto-error {
    background-color: rgba(var(--ion-color-danger-rgb), 0.2);
    border: 1px solid var(--ion-color-danger);
    border-radius: 5px;
    color: var(--ion-color-danger);
    padding: .25rem .5rem;
  }
</style>