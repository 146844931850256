<template>
  <ion-item class="flex">
    <ion-label position="stacked" v-if="label">
      {{ label }}
    </ion-label>
    <ion-input
      :type="inputType"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :value="value"
      @ionInput="onInput($event)"
    />
    <ion-icon v-if="value" :icon="showHideIcon" slot="end" style="height: 100%" @click="toggleShowHide"/>
  </ion-item>
</template>

<script>
  export default {
    emits: [
      'showPassword', 'input'
    ],
    props: {
      label: {
        type: String,
      },
      autocomplete: {
        type: String,
        default: 'new-password',
      },
      value: {
        type: String,
      },
      placeholder: {
        type: String,
      },
      passwordVisible: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        showPassword: this.passwordVisible,
      }
    },
    watch: {
      passwordVisible(newValue) {
        this.showPassword = newValue;
      }
    },
    computed: {
      showHideIcon() {
        return this.showPassword ? 'eye-off' : 'eye';
      },
      inputType() {
        return this.showPassword ? 'text' : 'password';
      }
    },
    methods: {
      toggleShowHide() {
        this.showPassword = !this.showPassword;
        this.$emit('showPassword', this.showPassword);
      },
      onInput($event) {
        this.$emit('input', $event.target.value);
      }
    }
  }
</script>