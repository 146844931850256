<template>
  <div>
    <ion-content>
      <div class="loading-container">
        <div class="center">
          <ion-spinner class="mr-4" :name="loadingSpinner" /><ion-text>{{ title }}...</ion-text>
        </div>
      </div>
    </ion-content>
  </div>
</template>

<style scoped>
  .loading-container {
    display: flex;
    height: 100%;
    width: 100%;
  }

  .center {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>

<script>
  import { loadingSpinner } from "@/configs";

  export default {
    props: {
      title: {
        default: 'Loading'
      }
    },
    data() {
      return {
        loadingSpinner
      }
    }
  }
</script>