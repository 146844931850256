import Vue from 'vue';
import { routeNames } from '../config';

/**
 * Can middleware
 * Tests if the user is permitted to enter the route based on the Gate policies
 */
class Can {

    /**
     *
     * Handle the middleware action
     *
     * @param String|{{name: string, params: string|Object}|*} params  "can:type.action"
     * @returns {Error}
     */
    handle({to, params}) {

        const {action, type, model, args} = this.parseParams(to, params);

        if (Vue.prototype.$gate && !Vue.prototype.$gate.can(action, type, model, args)) {
            return { name: routeNames.NOT_ACCESS };
        }
    }

    /**
     *
     * @param String|Object params
     * @returns {action: string, type: string}
     */
    parseParams(to, params) {
        if (params instanceof Object) {
            return params;
        }

        let [type, action] = params.split('.', 2);
        let model = undefined;

        if (action.indexOf(',') > -1 ) {
            let parts = action.split(',')
            action = parts.shift()
            model = this.resolveModel(to, parts.shift());
        }

        return {
            action,
            type,
            model
        }
    }

    resolveModel(to, model) {
        for (let i in to.params) {
            model = model.replace(`{${i}}`, to.params[i]);
        }
        return model;
    }

}

export default new Can();
