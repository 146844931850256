import Vue from 'vue';
import VueClipboard from 'vue-clipboard2'
import Vuelidate from 'vuelidate'
import VueI18n from 'vue-i18n';
import Ionic from '@ionic/vue'
import VueMoment from 'vue-moment';
import VueMeta from 'vue-meta';
import VueGtag from "vue-gtag";
import router from '../router';
import VueObserveVisibility from 'vue-observe-visibility';

const resourcesUrl = `${window.location.origin}/`;

export const registerPlugins = () => {
  Vue.use(VueObserveVisibility);
  Vue.use(Vuelidate);
  Vue.use(VueI18n);
  Vue.use(VueClipboard);
  Vue.use(Ionic, {resourcesUrl});
  Vue.use(VueMoment);
  Vue.use(VueMeta);
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_GTAG_ID }
  }, router); 
}
