export const FEATURE_FLAG_MAPPING = {
  CHARGE_APPLICATION_FEE_TO_RIDERS: 'charge_application_fee_to_riders',
  CAN_CREATE_COMPETITIONS: 'can_create_competitions',
  CAN_SEE_PAYOUTS: 'canseepayouts',
  CAN_SEE_TRANSFERS: 'can_see_transfers',
  CAN_SELL_PRODUCTS_AT_EVENTS: 'can_sell_products_at_events',
  EXPORT_CHECKINS: 'export_checkins',
  EXPORT_ORDERS: 'export_orders',
  MANUAL_MEMBERSHIPS: 'manual_memberships',
  EXPORT_MULTI_EVENT_ATTENDEES: 'export_multi_event_attendees',
  FORCE_UPDATE: 'force_update',
  GLOBAL_SEARCH: 'global_search',
}
