<template>
  <ion-app id="app">
    <ion-vue-router />
  </ion-app>
</template>

<script>
    import { mapState } from 'vuex';
    import hotkeys from 'hotkeys-js';
    import { App } from '@capacitor/app'

    import UpdateAppModal from './components/modals/UpdateAppModal.vue';
    import MaintenanceModeModal from './components/modals/MaintenanceModeModal.vue';

    let forceUpdateModal;
    let maintenanceModeModal;

    export default {
      metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Moto Inside',
        // all titles will be injected into this template
        titleTemplate: '%s'
      },
      computed: mapState({
        shouldUpdate: state => state.updateApp.shouldUpdate,
        maintenanceMode: state => state.updateApp.maintenanceMode,
      }),
      watch: {
        async shouldUpdate(newValue) {

          // App should display a modal
          if(newValue && !forceUpdateModal) {
            forceUpdateModal = await this.$ionic.modalController
              .create({
                backdropDismiss: false,
                component: UpdateAppModal,
                componentProps: {
                  parent: this,
                },
              });

            // Release the modal if it was dismissed
            forceUpdateModal.onDidDismiss().then( () => { forceUpdateModal = undefined } )

            await forceUpdateModal.present();
          }

          // Modal should be dismissed
          if (!newValue && forceUpdateModal) {
            await forceUpdateModal.dismiss();
          }
        },
        async maintenanceMode(newValue) {

          // App should display a modal
          if(newValue && !maintenanceModeModal) {
            maintenanceModeModal = await this.$ionic.modalController
              .create({
                backdropDismiss: false,
                component: MaintenanceModeModal,
                componentProps: {
                  parent: this,
                },
              });

            // Release the modal if it was dismissed
            maintenanceModeModal.onDidDismiss().then( () => { maintenanceModeModal = undefined } )

            await maintenanceModeModal.present();
          }

          // Modal should be dismissed
          if (!newValue && maintenanceModeModal) {
            await maintenanceModeModal.dismiss();
          }
        }
      },
      async created() {
        App.addListener('appUrlOpen', (data) => {
          if(data.url.includes(`api.motoinside.app`)) {
            window.location.replace(data.url);
          }

          if(data.url.includes(`app.motoinside.app`)) {
            window.location.replace(data.url);
          }
        });

        hotkeys('/', (event) => {
          event.preventDefault();
          this.$router.push({ name: this.$routeNames.SEARCH });
        });
      },
    }
</script>

