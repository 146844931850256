<template>
  <ion-button expand="full" :disabled="disabled" :color="color" @click="$emit('click')">
    <ion-text v-if="!loading">
      <slot />
    </ion-text>
    <ion-spinner v-else :name="loadingSpinner" :color="spinnerColor" />
  </ion-button>
</template>

<script>
import { loadingSpinner } from '@/configs';

export default {
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    color: {
      validator: (value) => ['primary', 'moto'].indexOf(value) !== -1,
      default: 'primary'
    }
  },
  data() {
    return {
      loadingSpinner
    }
  },
  computed: {
    spinnerColor() {
      return this.color === 'primary' ? 'white' : 'primary';
    }
  }
}
</script>