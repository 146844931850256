<template>
  <base-modal :title="$t('maintenancemodetitle')">
    <ion-content class="ion-padding">
      {{ $t('maintenancemodemessage') }}
    </ion-content>
  </base-modal>
</template>

<script>
  import BaseModal from './BaseModal.vue'

  export default {
    components: {
      BaseModal,
    },
  }
</script>