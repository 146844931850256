import Vue from 'vue';
import * as moment from 'moment';
import numeral from 'numeral';

import { formatDateTime, priceAmount, stripeAmount } from '@/utils';

export const registerFilters = () => {
  Vue.filter('formatDateTime', formatDateTime);

  Vue.filter('toLocalTime', function(value) {
    if (value) {
      return moment.utc(value).local();
    }
  });

  Vue.filter('stripeAmount', stripeAmount)

  Vue.filter('priceAmount', priceAmount)

  Vue.filter("formatNumber", function (value) {
    return numeral(value).format("0.  0"); // displaying other groupings/separators is possible, look at the docs
  });

  Vue.filter('formatHours', function(value) {
    if (value) {
      return moment(String(value)).format('HH:mm')
    }
  });

  Vue.filter('formatDate', function(value) {
    if (value) {
      return moment(String(value)).utc().format('DD-MM-YYYY')
    }
  })

  Vue.filter('uppercase', function(value) {
    if (value) {
      return String(value).toUpperCase()
    }
    return value;
  })
}

