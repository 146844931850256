/*
 * Defines our list of route names as a constant
 */
export const routeNames = {
  SEARCH: 'search',
  EDIT_EVENT: 'edit-event',
  CREATE_EVENT: 'create-event',
  CREATE_COMPETITION: 'create-competition',
  HOME: 'home',
  NEWS: 'news',
  SUBSCRIBED: 'subscribed',
  EVENTS: 'events',
  EVENT: 'event',
  THANKS: 'thanks',
  FACILITIES: 'facilities',
  MAKE_FACILITY: 'make-facility',
  EDIT_FACILITY: 'edit-facility',
  FACILITY: 'facility',
  PROFILE: 'profile',
  NOT_FOUND: 'error-404',
  NOT_ACCESS: 'error-403',
  FACILITY_UPCOMING_EVENTS: 'facility-upcoming-events',
  FACILITY_PASSED_EVENTS: 'facility-passed-events',
  FACILITY_MEMBERS_BULK_EDIT: 'facility-members-bulk-edit',
  FACILITY_PAYOUTS: 'facility-payouts',
  FACILITY_TRANSFERS: 'facility-transfers',
  FACILITY_TOP_UP_THANKS: 'facility-top-up-thanks',
  NEWS_SINGLE: 'news-single',
  CHECKIN_TRAINING: 'checkin-training',
  STRIPE_CONNECT: 'stripe-connect',
  LOGIN: 'login',
  REGISTER: 'register',
  SCANNER: 'scanner',
  CHECKED_IN_LIST: 'checkedin-list',
  CHECKIN: 'checkin',
  TICKET_CHECKIN: 'ticketCheckIn',
  PASSWORD_FORGOTTEN: 'password-forgotten',
  RESEND_VERIFICATION_MAIL: 'resend-verification-mail',
  CHOOSE_NEW_PASSWORD: 'choose-new-password',
  PROFILE_ORDERS: 'profile-orders',
  PROFILE_CHANGE_INFORMATION: 'profile-change-information',
  MANAGED_FACILITIES: 'managed-facilities',
  EMAIL_VERIFICATION_SUCCESS: 'email-verification-success',
  FACILITY_ADVERTISEMENT: 'facility-advertisement',
}
