import Gate from '@/policies/Gate'
import Vue from 'vue';
import { apolloClient, AUTH_TOKEN, REFRESH_TOKEN } from "@/vue-apollo";
import { GET_PENDING_EMAIL_CHANGES, GET_TFA_SECRETS, GET_USER, HAS_TFA_ENABLED } from "@/graphql/queries";
import {
  CONFIRM_EMAIL_CHANGE, DELETE_EMAIL_CHANGE, DISABLE_TFA_WITH_EMAIL, DISABLE_TFA_WITH_PASSWORD, ENABLE_TFA,
  LOGIN_USER,
  LOGIN_WITH_REFRESH_TOKEN,
  NEW_PASSWORD_WITH_TOKEN, REQUEST_EMAIL_CHANGE, SEND_TFA_DISABLE_EMAIL, UPDATE_USER,
  QUICK_REGISTER_USER,
} from "@/graphql/mutations";
import router from "@/router";
import { routeNames } from "@/router/config";
import { JWTIsValid } from "@/utils/jwt";
import { hasAuthError } from "@/utils";
import { logoutAndRedirect } from "@/utils/logoutAndRedirect";
import { captureException } from "@/utils/captureException";
import {
  SET_CURRENT_USER_MUTATION,
  ADD_FACILITY_TO_USER_MUTATION,
  SET_PENDING_EMAIL_CHANGES_MUTATION
} from "@/store/store-mutations";
import {
  CURRENT_USER_GETTER,
  CURRENT_USER_SUBSCRIPTIONS_GETTER,
  PENDING_EMAIL_CHANGES_GETTER
} from "@/store/store-getters";
import {
  CLOSE_ALL_MODALS,
  CONFIRM_EMAIL_CHANGE_ACTION,
  DELETE_EMAIL_CHANGE_ACTION,
  DISABLE_TFA_WITH_EMAIL_ACTION,
  DISABLE_TFA_WITH_PASSWORD_ACTION,
  ENABLE_TFA_ACTION,
  FETCH_PENDING_EMAIL_CHANGES_ACTION,
  GET_TFA_SECRETS_ACTION,
  GET_USER_BY_TOKEN_ACTION,
  HAS_TFA_ENABLED_ACTION,
  LOGIN_USER_ACTION,
  LOGIN_WITH_REFRESH_TOKEN_ACTION,
  LOGOUT_AND_REDIRECT_ACTION,
  NEW_PASSWORD_WITH_TOKEN_ACTION,
  QUICK_REGISTER_USER_ACTION,
  REFRESH_EXPIRED_ACCESS_TOKEN_ACTION,
  REQUEST_EMAIL_CHANGE_ACTION,
  SEND_TFA_DISABLE_EMAIL_ACTION,
  UPDATE_USER_ACTION,
} from "@/store/store-actions";
import { cloneDeep } from "lodash";
import authStorage from "../../utils/authStorage";

export default {
  state: {
    currentUser: null,
    pendingEmailChanges: [],
  },
  mutations: {
    [SET_CURRENT_USER_MUTATION]: (state, user) => {
      if (!Vue.prototype.$gate) {
        Vue.prototype.$gate = new Gate(user)
      } else {
        Vue.prototype.$gate.setUser(user)
      }

      state.currentUser = user;
    },
    [ADD_FACILITY_TO_USER_MUTATION]: (state, facility) => {
      state.currentUser.facilities.push(facility);
    },
    [SET_PENDING_EMAIL_CHANGES_MUTATION]: (state, pendingEmailChanges) => {
      state.pendingEmailChanges = pendingEmailChanges;
    }
  },
  getters: {
    [CURRENT_USER_GETTER]: state => {
      return state.currentUser
    },
    [CURRENT_USER_SUBSCRIPTIONS_GETTER]: state => {
      return state.currentUser?.subscriptions;
    },
    [PENDING_EMAIL_CHANGES_GETTER]: state => {
      return state.pendingEmailChanges
    },
  },
  actions: {
    async [REFRESH_EXPIRED_ACCESS_TOKEN_ACTION]({dispatch, state}) {
      try {

        const access_token = await authStorage.getItem(AUTH_TOKEN);
        const refresh_token = await authStorage.getItem(REFRESH_TOKEN);

        const tokenRefreshRequired = access_token && refresh_token && !JWTIsValid(access_token);
        if (tokenRefreshRequired) {
          return await dispatch(LOGIN_WITH_REFRESH_TOKEN_ACTION, {refresh_token});
        }

        const currentUserLoggedOut = (!access_token || !JWTIsValid(access_token)) && (state.currentUser);
        if (currentUserLoggedOut) {
          // Redirect the user to the login page
          return await logoutAndRedirect()
        }

      } catch (error) {
        if (hasAuthError(error)) {
          await logoutAndRedirect()
          return;
        }

        throw error;
      }
    },

    async [GET_USER_BY_TOKEN_ACTION]({commit}, params) {
      const queryOptions = {
        query: GET_USER
      };

      if (params?.fresh) {
        queryOptions.fetchPolicy = 'network-only';
      }

      try {
        const hasToken = await authStorage.getItem(AUTH_TOKEN);
        if (hasToken) {
          const {data: getUserByToken} = await apolloClient.query(queryOptions)

          commit(SET_CURRENT_USER_MUTATION, cloneDeep(getUserByToken?.getUserByToken));
        }
      } catch (error) {
        captureException(error);
      }
    },

    async [LOGIN_WITH_REFRESH_TOKEN_ACTION]({commit}, {refresh_token}) {
      const response = await apolloClient.mutate({
        mutation: LOGIN_WITH_REFRESH_TOKEN,
        variables: {
          data: {
            refresh_token,
          }
        },
      });

      const loginWithRefreshToken = cloneDeep(response.data.loginWithRefreshToken)

      commit(SET_CURRENT_USER_MUTATION, loginWithRefreshToken.user);
      await authStorage.setItem(AUTH_TOKEN, loginWithRefreshToken.access_token);
      await authStorage.setItem(REFRESH_TOKEN, loginWithRefreshToken.refresh_token);
    },

    async [LOGIN_USER_ACTION]({commit}, {email, password, tfa_code}) {
      const response = await apolloClient.mutate({
        mutation: LOGIN_USER,
        variables: {
          data: {
            email,
            password,
            tfa_code
          }
        },
      });

      const loginUser = cloneDeep(response.data.loginUser)

      commit(SET_CURRENT_USER_MUTATION, loginUser.user);
      await authStorage.setItem(AUTH_TOKEN, loginUser.access_token);
      await authStorage.setItem(REFRESH_TOKEN, loginUser.refresh_token);
    },

    /**
     * Loops through all (max-10) open ionic modals, and dismisses them
     *
     * @returns {Promise<void>}
     */
    async [CLOSE_ALL_MODALS]() {
      let topModal = null;

      // Close the top-10 modals
      for (let tel = 0; tel < 10; tel++) {
        topModal = await this._vm.$ionic.modalController.getTop();

        if (!topModal) {
          break;
        }

        await topModal.dismiss();
      }
    },

    async [LOGOUT_AND_REDIRECT_ACTION]({commit, dispatch}, routerPushParams = {
      name: routeNames.LOGIN,
    }) {
      await dispatch(CLOSE_ALL_MODALS);

      await authStorage.removeItem(AUTH_TOKEN);
      await authStorage.removeItem(REFRESH_TOKEN);
      commit(SET_CURRENT_USER_MUTATION, null);

      try {
        await apolloClient.clearStore();
      } catch (error) {
        // We need to ensure that we will finally land on the target route, even if the client causes errors
        captureException(error);
      }

      // We catch the exception from the route, we expect the route to error because of a forced redirect to LOGIN
      return router.push(routerPushParams).catch(() => null);
    },

    async [NEW_PASSWORD_WITH_TOKEN_ACTION]({commit}, {token, password, password_confirmation}) {
      const response = await apolloClient.mutate({
        mutation: NEW_PASSWORD_WITH_TOKEN,
        variables: {
          data: {
            token,
            password,
            password_confirmation,
          },
        },
      });

      const newPasswordWithToken = cloneDeep(response.data.newPasswordWithToken)

      commit(SET_CURRENT_USER_MUTATION, newPasswordWithToken.user);
      await authStorage.setItem(AUTH_TOKEN, newPasswordWithToken.access_token);
      await authStorage.setItem(REFRESH_TOKEN, newPasswordWithToken.refresh_token);

      return newPasswordWithToken;
    },

    [REQUEST_EMAIL_CHANGE_ACTION](context, {email}) {
      return apolloClient.mutate({
        mutation: REQUEST_EMAIL_CHANGE,
        variables: {
          email
        }
      });
    },

    [CONFIRM_EMAIL_CHANGE_ACTION](context, {email, otp, tfa_code}) {
      return apolloClient.mutate({
        mutation: CONFIRM_EMAIL_CHANGE,
        variables: {
          email,
          otp,
          tfa_code
        }
      });
    },

    [DELETE_EMAIL_CHANGE_ACTION](context, {email}) {
      return apolloClient.mutate({
        mutation: DELETE_EMAIL_CHANGE,
        variables: {
          email,
        }
      });
    },

    async [FETCH_PENDING_EMAIL_CHANGES_ACTION]({commit}) {
      const response = await apolloClient.query({
        query: GET_PENDING_EMAIL_CHANGES,
        fetchPolicy: 'network-only'
      })

      const getPendingEmailChanges = cloneDeep(response.data.getPendingEmailChanges);

      commit(SET_PENDING_EMAIL_CHANGES_MUTATION, getPendingEmailChanges);

      return getPendingEmailChanges;
    },

    async [UPDATE_USER_ACTION](context, {data}) {
      const response = await apolloClient.mutate({
        mutation: UPDATE_USER,
        variables: {
          data,
        }
      });

      return cloneDeep(response.data.updateUser);
    },

    [HAS_TFA_ENABLED_ACTION](context, {email}) {
      return apolloClient.query({
        query: HAS_TFA_ENABLED,
        variables: {
          email
        },
        fetchPolicy: 'network-only'
      }).then((response) => response.data.hasTFAEnabled);
    },

    [GET_TFA_SECRETS_ACTION]() {
      return apolloClient.query({
        query: GET_TFA_SECRETS,
        fetchPolicy: 'network-only'
      }).then((response) => response.data.getTFASecrets);
    },

    [ENABLE_TFA_ACTION](context, {password, tfa_code}) {
      return apolloClient.mutate({
        mutation: ENABLE_TFA,
        variables: {
          password,
          tfa_code
        },
      }).then((response) => response.data.enableTFA);
    },

    [DISABLE_TFA_WITH_PASSWORD_ACTION](context, {password, tfa_code}) {
      return apolloClient.mutate({
        mutation: DISABLE_TFA_WITH_PASSWORD,
        variables: {
          password,
          tfa_code
        },
      }).then((response) => response.data.disableTFAWithPassword);
    },

    [DISABLE_TFA_WITH_EMAIL_ACTION](context, {email, password, tfa_disable_code}) {
      return apolloClient.mutate({
        mutation: DISABLE_TFA_WITH_EMAIL,
        variables: {
          email,
          password,
          tfa_disable_code
        },
      }).then((response) => response.data.disableTFAWithEmail);
    },

    [SEND_TFA_DISABLE_EMAIL_ACTION](context, {email}) {
      return apolloClient.mutate({
        mutation: SEND_TFA_DISABLE_EMAIL,
        variables: {
          email
        },
      }).then((response) => response.data.sendTFADisableCode);
    },

    async [QUICK_REGISTER_USER_ACTION]({commit}, {data}) {
      const response = await apolloClient.mutate({
        // Mutation
        mutation: QUICK_REGISTER_USER,
        variables: {
          data: {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            email_confirmation: data.email_confirmation,
          }
        }
      });

      const quickRegisterUser = cloneDeep(response.data.quickRegisterUser);

      commit(SET_CURRENT_USER_MUTATION, quickRegisterUser.user);
      await authStorage.setItem(AUTH_TOKEN, quickRegisterUser.access_token);
      await authStorage.setItem(REFRESH_TOKEN, quickRegisterUser.refresh_token);

      return quickRegisterUser;
    },

  },

}