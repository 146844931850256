export default class NewsPolicy {

  /**
   *
   * @param user
   * @returns {boolean}
   */
  static create(user) {
    return user?.email?.endsWith('@motoinside.app');
  }

}
