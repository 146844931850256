<template>
  <ion-button :disabled="disabled" @click="$emit('click')">
    <ion-text v-if="!loading">
      <slot />
    </ion-text>
    <ion-spinner v-else :name="loadingSpinner" color="primary" />
  </ion-button>
</template>

<script>
import { loadingSpinner } from '@/configs';

export default {
  props: ['disabled', 'loading', 'expand'],
  data() {
    return {
      loadingSpinner
    }
  }
}
</script>