<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-back-button :default-href="defaultHref" text="" color="moto" />
      </ion-buttons>
      <ion-title color="white">
        {{ $t('motoinsideappname') }}
      </ion-title>
    </ion-toolbar>

    <slot />
  </ion-header>
</template>

<script>
    export default {
        props: {
            defaultHref: {
                type: String,
                default: '/',
            },
        }
    }
</script>
