<template>
  <base-modal :title="$t('forceupdatetitle')">
    <ion-content class="ion-padding">
      {{ $t('forceupdatemessage') }}
      <ion-button class="mt-4" expand="full" color="moto" @click="openStoreUrl">{{ (platform === 'ios') ? $t('openappstore') : $t('openplaystore') }}</ion-button>
    </ion-content>
  </base-modal>
</template>

<script>
  import BaseModal from './BaseModal.vue'
  import { Browser } from '@capacitor/browser';
  import { Capacitor } from "@capacitor/core";

  export default {
    components: {
      BaseModal,
    },
    data() {
      return {
        platform: Capacitor.getPlatform(),
      }
    },
    methods: {
      getStoreUrl()
      {
        return (this.platform === 'ios') ? 'https://apps.apple.com/nl/app/moto-inside/id1488893503' : 'https://play.google.com/store/apps/details?id=app.motoinside.motoinside'
      },
      async openStoreUrl() {
        const storeUrl = this.getStoreUrl();

        await Browser.open({url: storeUrl})
      },
    }
  }
</script>