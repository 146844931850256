export default class TrainingEventRegistrationsPolicy {
  static view(user, event) {
    let allowed = false;

    if(user?.facilities) {
      user.facilities.forEach(facility => {
        if(facility.id == event.organizer.id) {
          allowed = true
        }
      })
    }

    return allowed ;
  }
}
