<template>
  <base-modal :title="title" @close="closeMe">
    <template v-if="tab == tabs.LOGIN_EMAIL">
      <ion-content :disabled="loading">
        <ion-list :key="tabs.LOGIN_EMAIL">
          <ion-list-header>
            <ion-label>{{ $t('enteremailtocontinue') }}</ion-label>
          </ion-list-header>
          <ion-item>
            <ion-label position="stacked">
              {{ $t('email') }}
            </ion-label>
            <ion-input
              type="email"
              autocomplete="username"
              :placeholder="$t('email')"
              :value="credentials.email"
              @ionInput="credentials.email = $event.target.value" />
          </ion-item>
        </ion-list>
        <Error :error="error" />
      </ion-content>

      <ion-footer>
        <ion-grid>
          <ion-row>
            <ion-col size="12" size-sm="6" push-sm="6">
              <LoadingButton
                :loading="loading"
                :disabled="!credentials.email || loading"
                color="moto"
                @click="enterEmail"
              >{{ $t('continue') }}</LoadingButton>
            </ion-col>
            <ion-col size="12" size-sm="6" pull-sm="6">
              <ion-button @click="closeMe" expand="full" :disabled="loading">{{ $t('back') }}</ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-footer>
    </template>

    <template v-if="tab == tabs.LOGIN_PASSWORD">
      <ion-content :disabled="loading">
        <form @submit.prevent="login" autocomplete="off">
          <ion-list :key="tabs.LOGIN_PASSWORD">
            <ion-item>
              <ion-label position="stacked" color="white">{{ $t('loginwith') }}</ion-label>
              <ion-input type="text" readonly name="username" :value="credentials.email" disabled />
            </ion-item>
            <ShowHidePassword
              autocomplete="current-password"
              :label="$t('password')"
              :value="credentials.password"
              @input="credentials.password = $event"
              :placeholder="$t('password')"
              :passwordVisible="passwordVisible"
              @showPassword="passwordVisible = $event"
            />
            <ion-item v-if="hasTfa" key="otp">
              <ion-label position="stacked">{{ $t('onetimepassword') }}</ion-label>
              <ion-input
                type="text"
                class="text-3xl"
                min="6"
                max="6"
                :placeholder="$t('onetimepassword')"
                @ionInput="credentials.tfa_code = $event.target.value" />
            </ion-item>
          </ion-list>
          <Error :error="error" />
        </form>
      </ion-content>

      <ion-footer>
        <ion-grid>
          <ion-row>
            <ion-col size="12" size-sm="6" push-sm="6">
              <LoadingButton
                :loading="loading"
                :disabled="!credentials.password || loading"
                color="moto"
                @click="login"
              >{{ $t('continue') }}</LoadingButton>
            </ion-col>
            <ion-col size="12" size-sm="6" pull-sm="6">
              <ion-button @click="cancelLoginPassword" expand="full" :disabled="loading">{{ $t('back') }}</ion-button>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12" size-sm="6" text-center text-sm-left>
              <a href="#" @click.prevent="forgotPassword" :disabled="loading">
                <ion-text color="white">{{ $t('forgotpassword') }}</ion-text>
              </a>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-footer>
    </template>

    <template v-if="tab == tabs.VERIFY_EMAIL">
      <ion-content :disabled="loading">
        <ion-list :key="tabs.VERIFY_EMAIL">
          <ion-item>
            <ion-text>
              <p>{{ $t('emailisnotverifiedinfo') }}</p>
            </ion-text>
          </ion-item>
          <ion-item>
            <ion-button color="moto" @click="verifyEmail" :disabled="loading">{{ $t('resendverificationmail') }}</ion-button>
          </ion-item>
        </ion-list>
        <Error :error="error" />
      </ion-content>

      <ion-footer>
        <ion-grid>
          <ion-row>
            <ion-col size="12" size-sm="6">
              <ion-button @click="cancelVerifyEmail" expand="full" :disabled="loading">{{ $t('back') }}</ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-footer>
    </template>

    <template v-if="tab == tabs.REGISTER">
      <ion-content :disabled="loading">
        <form @submit.prevent="register" autocomplete="on">
          <ion-list :key="tabs.REGISTER">
            <ion-list-header>
              <ion-label>{{ $t('enteryourdetailstocontinue') }}</ion-label>
            </ion-list-header>
            <ion-item class="ion-margin-top">
              <ion-label position="stacked">{{ $t('email') }}</ion-label>
              <ion-input type="text" autocomplete="username" :placeholder="$t('email')" :value="credentials.email" @ionInput="credentials.email = $event.target.value" />
            </ion-item>
            <ion-item class="ion-margin-top">
              <ion-label position="stacked">{{ $t('confirmemail') }}</ion-label>
              <ion-input type="text" autocomplete="off" :placeholder="$t('confirmemail')" :value="credentials.email_confirmation" @ionInput="credentials.email_confirmation = $event.target.value" />
            </ion-item>
            <ion-item class="ion-margin-top">
              <ion-label position="stacked">{{ $t('firstname') }}</ion-label>
              <ion-input type="text" autocomplete="given-name" :placeholder="$t('firstname')" :value="credentials.first_name" @ionInput="credentials.first_name = $event.target.value" />
            </ion-item>
            <ion-item class="ion-margin-top">
              <ion-label position="stacked">{{ $t('lastname') }}</ion-label>
              <ion-input type="text" autocomplete="family-name" :placeholder="$t('lastname')" :value="credentials.last_name" @ionInput="credentials.last_name = $event.target.value" />
            </ion-item>
            <ion-item>
              <ion-grid class="mt-4">
                <ion-col>

                  <ion-text class="text-sm block">
                    {{ $t('basicinformationregister') }}
                  </ion-text>

                  <i18n tag='ion-text' path='privacypolicyquickregister' class='mt-5 text-sm block'>
                    <a href="https://motoinside.app/privacybeleid/" target="blank"><ion-text color="moto">{{ $t('privacypolicylabel') }}</ion-text></a>
                  </i18n>
                </ion-col>
              </ion-grid>
            </ion-item>
          </ion-list>

          <Error :error="error" />
        </form>
      </ion-content>

      <ion-footer>
        <ion-grid>
          <ion-row>
            <ion-col size="12" size-sm="6" push-sm="6">
              <LoadingButton
                :loading="loading"
                :disabled="registerDisabled"
                color="moto"
                @click="register"
              >{{ $t('confirm') }}</LoadingButton>
            </ion-col>
            <ion-col size="12" size-sm="6" pull-sm="6">
              <ion-button @click="cancelRegistration" expand="full" :disabled="loading">{{ $t('back') }}</ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-footer>
    </template>
  </base-modal>
</template>

<script>
  import BaseModal from './BaseModal.vue'
  import { CHECK_EMAIL_IS_AVAILABLE } from "@/graphql/queries";
  import { EmailAvailableEnum } from "@/graphql/enums";
  import ShowHidePassword from "@/components/inputs/ShowHidePassword";
  import { LOGIN_USER_ACTION, QUICK_REGISTER_USER_ACTION } from "@/store/store-actions";

  const TABS = {
    LOGIN_EMAIL: 'login-email',
    LOGIN_PASSWORD: 'login-password',
    VERIFY_EMAIL: 'verify-email',
    REGISTER: 'register',
  };
  export default {
    props: ['success', 'closeMe'],
    components: {
      ShowHidePassword,
      BaseModal,
    },
    data() {
      return {
        loading: false,
        passwordVisible: false,
        error: null,
        emailStatus: null,
        credentials: {
          email: null,
          email_confirmation: null,
          password: null,
          tfa_code: null,
          first_name: null,
          last_name: null,
        },
        tabs: TABS,
        tab: TABS.LOGIN_EMAIL,
        date: this.$moment(this.value),
      }
    },
    computed: {
      title() {
        switch (this.tab) {
          case TABS.LOGIN_EMAIL:
            return this.$t('yourdetails');
          case TABS.LOGIN_PASSWORD:
            return this.$t('login');
          case TABS.REGISTER:
            return this.$t('register');
          case TABS.VERIFY_EMAIL:
            return this.$t('resendverificationmail');
        }
        return this.$t('yourdetails');
      },
      hasTfa() {
        return this.emailStatus === EmailAvailableEnum.exists_tfa;
      },
      registerDisabled() {
        return (this.loading || !this.credentials.email || !this.credentials.email_confirmation || !this.credentials.first_name || !this.credentials.last_name);
      }
    },
    methods: {
      async checkEmailIsAvailable(email) {
        const response = await this.$apollo.query({
          query: CHECK_EMAIL_IS_AVAILABLE,
          variables: {
            email
          }
        });

        return response.data.checkEmailIsAvailable;
      },
      clearError() {
        this.error = null;
      },
      async enterEmail() {
        this.clearError();
        let available = null;
        try {
          available = await this.checkEmailIsAvailable(this.credentials.email);
        } catch (e) {
          this.error = e;
          return;
        }

        this.emailStatus = available;

        switch (this.emailStatus) {
          case EmailAvailableEnum.exists:
          case EmailAvailableEnum.exists_tfa:
            this.clearPasswords();
            this.tab = TABS.LOGIN_PASSWORD;
            return;
          case EmailAvailableEnum.unverified:
            this.tab = TABS.VERIFY_EMAIL;
            return;
          case EmailAvailableEnum.available:
            this.tab = TABS.REGISTER;
            return;
        }
      },
      clearPasswords() {
        this.credentials.password = null;
        this.credentials.tfa_code = null;
      },
      cancelLoginPassword() {
        this.clearPasswords();
        this.tab = TABS.LOGIN_EMAIL;
      },
      cancelVerifyEmail() {
        this.tab = TABS.LOGIN_EMAIL;
      },
      cancelRegistration() {
        this.tab = TABS.LOGIN_EMAIL;
        this.credentials.email_confirmation = null;
      },
      onDateChange($event) {
        this.date = this.$moment(new Date($event.target.value));
      },
      async login() {
        this.clearError();
        this.loading = true;

        try {
          await this.$store.dispatch(LOGIN_USER_ACTION, {
            email: this.credentials.email,
            password: this.credentials.password,
            tfa_code: this.credentials.tfa_code,
          });
        } catch (error) {
          this.error = error;

          return;
        } finally {
          this.loading = false;
        }

        this.success();

      },
      async forgotPassword() {
        await this.$router.push({name: this.$routeNames.PASSWORD_FORGOTTEN});
        this.closeMe();
      },
      async verifyEmail() {
        await this.$router.push({name: this.$routeNames.RESEND_VERIFICATION_MAIL});
        this.closeMe();
      },
      async register() {
        this.clearError();
        this.loading = true;

        try {
          await this.$store.dispatch(QUICK_REGISTER_USER_ACTION, {
            data: {
              first_name: this.credentials.first_name,
              last_name: this.credentials.last_name,
              email: this.credentials.email,
              email_confirmation: this.credentials.email_confirmation,
            }
          });
        } catch (e) {
          this.error = e;
          return;
        } finally {
          this.loading = false;
        }

        this.success();
      }
    }
  }
</script>