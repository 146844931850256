import { REQUIREMENT_BETWEEN_AGE, StripeStatus } from "@/graphql/enums";
import * as moment from "moment-timezone";
import { GET_ORDERS_FOR_EVENT, GET_ORDERS_FOR_EVENTS } from "@/graphql/queries";
import { CURRENT_USER_GETTER, CURRENT_USER_SUBSCRIPTIONS_GETTER } from "@/store/store-getters";
import { CHECKOUT_COMPETITION, UPDATE_PAYMENT_METHOD_FOR_ORDER } from "@/graphql/mutations";
import { ValidationError } from "@/utils/validation";
import { find, uniq } from "lodash";
import { PRODUCT_FAMILIES } from "@/configs";
import { createPivotable, quantityAvailable, stockAvailable } from "@/utils";
import { apolloClient } from "@/vue-apollo";
import { getI18n } from "@/translations";

export default {
  state: {
    date_for_age_requirements: moment().toISOString(),
    form: null,
    presentation: null,
    form_values: {},
    additional_fields: [],
    selected_tickets: [],
    selected_products: [],
    products_for_sale: [],
    tickets_for_sale: [],
    paid_orders: [],
    error: null,
    termsMon: {
      checkboxRisk: false,
      checkboxMedical: false,
      checkboxPrivacy: false,
    }
  },
  getters: {
    getSelectedTickets: state => state.selected_tickets,
    getSelectedProducts: state => state.selected_products,
    getBuyTicketsError: state => state.error,
    getRegistrationForm: state => state.form,
    getRegistrationPresentation: state => state.presentation,
    getRegistrationFormValues: state => state.form_values,
    getRegistrationAdditionalFields: state => state.additional_fields,
    getSelectedTicketQuantity: state => (ticket) => {
      return state.selected_tickets.find(selected_ticket => selected_ticket.ticket_id === ticket.id)?.quantity || 0;
    },
    getSelectedProductQuantity: state => (product_for_sale) => {
      return state.selected_products.find(selected_product => selected_product.product_for_sale_id === product_for_sale.id)?.quantity || 0;
    },
    getPaidOrders: state => state.paid_orders,
    requirementsMet: state => (objectWithRequirements) => {
      if (!objectWithRequirements.requirements) {
        return true;
      }

      const failedRequirements = objectWithRequirements.requirements.some( (requirement) => {
        if (!state.form_values?.date_of_birth) {
          return true;
        }

        let ageAtTimeOfEvent;
        switch (requirement.type) {
          case REQUIREMENT_BETWEEN_AGE:
            // Age is the difference in dates between the start of the event and the birthdate of the person
            ageAtTimeOfEvent = moment(state.date_for_age_requirements).diff(state.form_values.date_of_birth, 'years');

            // Fail minimum age
            if (requirement.params?.min && (requirement.params?.min > ageAtTimeOfEvent)) {
              return true;
            }
            // Fail maximum age
            if (requirement.params?.max && (requirement.params?.max < ageAtTimeOfEvent)) {
              return true;
            }

            break;
        }

        return false;
      })

      return !failedRequirements;
    },
    userHasPaidProductForEvent: (state) => (productForSale) => {
      if (!state.paid_orders) {
        return false;
      }

      return state.paid_orders.some( (order) => {
        return order.products.some( (paidProduct)  => {
          if (paidProduct.pivot?.refunded_at) {
            return false;
          }
          return parseInt(paidProduct.productVariant.id) === parseInt(productForSale.product_variant_id);
        })
      })
    },
    userHasPaidTicketForEvent: state => (ticket) => {
      if (!state.paid_orders.length) {
        return false;
      }

      return state.paid_orders.some( (order) => {
        return order.tickets.some( (paidTicket)  => {
          if (paidTicket.pivot?.refunded_at) {
            return false;
          }
          return parseInt(paidTicket.id) === parseInt(ticket.id);
        })
      })
    },
    getLineItems: (state) => {
      return state.selected_tickets.concat(state.selected_products);
    },
    productForSaleIsDisabled: (state, getters, rootState, rootGetters) => productForSale => {

      if (!getters.requirementsMet(productForSale)) {
        return true;
      }

      // product is required, and user has paid for the product already and only one product can be bought
      if (productForSale.is_required && (productForSale.max_per_order === 1) && getters.userHasPaidProductForEvent(productForSale)) {
        return true;
      }

      if(!productForSale.is_for_members) {
        return false;
      }

      if(!productForSale.subscriptions.length) {
        return false;
      }

      const subscriptionIds = (rootGetters[CURRENT_USER_SUBSCRIPTIONS_GETTER] || []).map( subscription => subscription.id );

      if((!subscriptionIds.length) && productForSale.subscriptions.length) {
        return true;
      }

      return !productForSale.subscriptions.some(subscription => subscriptionIds.includes(subscription.id));
    },
    ticketIsDisabled: (state, getters, rootState, rootGetters) => ticket => {

      if (!getters.requirementsMet(ticket)) {
        return true;
      }

      if (ticket.is_required && (ticket.max_tickets_per_ticket === 1) && getters.userHasPaidTicketForEvent(ticket)) {
        return true;
      }

      if(!ticket.is_for_members) {
        return false;
      }

      if(!ticket.subscriptions.length) {
        return false;
      }

      const subscriptionIds = (rootGetters[CURRENT_USER_SUBSCRIPTIONS_GETTER] || []).map( subscription => subscription.id );

      if((!subscriptionIds.length) && ticket.subscriptions.length) {
        return true;
      }

      return !ticket.subscriptions.some(subscription => subscriptionIds.includes(subscription.id));
    },
    getProductsForSale: (state) => state.products_for_sale,
    getTicketsForSale: (state) => state.tickets_for_sale,
    hasProductsForSale: (state) => !!state.products_for_sale?.length,
    hasTicketsForSale: (state) => !!state.tickets_for_sale?.length,
    requireTermsMon: (state) => {
      const displayMonTicketTerms = state.selected_tickets.some( (selectedTicket) => {
        const ticket = find(state.tickets_for_sale, {id: selectedTicket.ticket_id});
        return (ticket?.name?.toLowerCase()?.includes('daglicentie'));
      });

      const displayMonProductTerms = state.selected_products.some( (selectedProduct) => {
        const product = find(state.products_for_sale, {id: selectedProduct.product_for_sale_id});
        return (product?.productVariant?.product?.productFamily?.id === PRODUCT_FAMILIES.MON_WA);
      });

      return displayMonProductTerms || displayMonTicketTerms;
    },
    termsMon: (state) => state.termsMon,
    requiredTermsFilledIn: (state, getters) => {
      if(!getters.requireTermsMon) {
        return true;
      }

      return state.termsMon.checkboxRisk && state.termsMon.checkboxMedical && state.termsMon.checkboxPrivacy;
    },
    getEventIdsWithProductsOrTicketsSelected: (state) => {
      // Only enforce the events that have any selected products OR are included in the enforceEventIds
      const selectedProductIds = state.selected_products.map((product) => product.product_for_sale_id);
      const eventIdsWithProductsSelected = state.products_for_sale.filter((product) => selectedProductIds.includes(product.id)).map((product) => product.event_id);

      const selectedTicketIds = state.selected_tickets.map((ticket) => ticket.ticket_id);
      const eventIdsWithTicketsSelected = state.tickets_for_sale.filter((ticket) => selectedTicketIds.includes(ticket.id)).map((ticket) => ticket.event_id);

      return uniq(eventIdsWithProductsSelected.concat(...eventIdsWithTicketsSelected));
    },
    requiredTicketsAreNotSelected: (state, getters) => (onlyEventsIn = []) => {
      const ticketsForEvents = state.tickets_for_sale.filter((productForSale) => onlyEventsIn.includes(productForSale.event_id))

      return ticketsForEvents.some( (ticket) => {
        return (ticket.is_required && !getters.getSelectedTicketQuantity(ticket) && !getters.userHasPaidTicketForEvent(ticket));
      })
    },
    requiredProductsAreNotSelected: (state, getters) => (onlyEventsIn = []) => {
      const productsForEvents = state.products_for_sale.filter((productForSale) => onlyEventsIn.includes(productForSale.event_id));

      return productsForEvents.some( (productForSale) => {
        return (productForSale.is_required && !getters.getSelectedProductQuantity(productForSale) && !getters.userHasPaidProductForEvent(productForSale));
      })
    },
    anySelectedTicketIsSoldOut: (state) => {
      return state.selected_tickets.some( (selectedTicket) => {
        const ticket = find(state.tickets_for_sale, {id: selectedTicket.ticket_id});
        return (quantityAvailable(ticket) < selectedTicket.quantity);
      })
    },
    anySelectedProductIsSoldOut: (state) => {
      return state.selected_products.some( (selectedProduct) => {
        const product = find(state.products_for_sale, {id: selectedProduct.product_for_sale_id});
        return (stockAvailable(product) < selectedProduct.quantity);
      });
    },
    totalTicketPrice: (state) => {
      return state.selected_tickets.reduce((totalPrice, currentTicket) => {
        const ticket = find(state.tickets_for_sale, {id: currentTicket.ticket_id});
        return totalPrice + (ticket.price * currentTicket.quantity);
      }, 0)
    },
    totalProductPrice: (state) => {
      return state.selected_products.reduce( (totalPrice, selectedProduct) => {
        const product = find(state.products_for_sale, {id: selectedProduct.product_for_sale_id});
        return totalPrice + product.productVariant.price * selectedProduct.quantity;
      }, 0)
    },
    totalPrice: (state, getters) => {
      return getters.totalTicketPrice + getters.totalProductPrice;
    }
  },
  mutations: {
    clearTermsMon: (state) =>  {
      state.termsMon.checkboxMedical = false;
      state.termsMon.checkboxRisk = false;
      state.termsMon.checkboxPrivacy = false;
    },

    toggleTermsMon: (state, {key, checked}) => {
      state.termsMon[key] = !!checked
    },

    clearPaidOrders: (state) => {
      state.paid_orders.splice(0);
    },

    // Only add non-existing orders
    addPaidOrders: (state, paidOrders) => {
      const filtered = paidOrders.filter( paidOrder => {
        return state.paid_orders.findIndex( (order) => order.id === paidOrder.id ) === -1;
      })
      state.paid_orders.push(...filtered);
    },

    setDateForAgeRequirements: (state, date) => {
      state.date_for_age_requirements = date;
    },
    setRegistrationForm: (state, form) => {
      state.form = {
        ...form,
        profile_fields: (typeof form.profile_fields === 'string') ? JSON.parse(form.profile_fields) : {...form.profile_fields},
        additional_fields: (typeof form.additional_fields === 'string') ? JSON.parse(form.additional_fields) : [...form.additional_fields],
      }
    },
    /**
     * Merges the currently defined registration form with the given registration form
     *
     * This will merge both profile_fields and additional_fields
     *
     * @param state
     * @param form
     */
    mergeRegistrationForm: (state, form) => {
      const profile_fields = (typeof form.profile_fields === 'string') ? JSON.parse(form.profile_fields) : {...form.profile_fields};

      // Merge profile fields
      Object.keys(profile_fields).forEach( (key) => {
        if (!state.form.profile_fields[key]) {
          state.form.profile_fields[key] = {...profile_fields[key]};
        } else {
          if (!state.form.profile_fields[key].toggled && profile_fields[key].toggled) {
            state.form.profile_fields[key].toggled = true;
          }
          if (!state.form.profile_fields[key].required && profile_fields[key].required) {
            state.form.profile_fields[key].required = true;
          }
        }
      })

      // Merge additional fields
      const additional_fields = (typeof form.additional_fields === 'string') ? JSON.parse(form.additional_fields) : [...form.additional_fields];
      additional_fields.forEach( (additional_field) => {
        const existingIndex = state.form.additional_fields.findIndex((item) => item.label === additional_field.label);
        if (existingIndex === -1) {
          state.form.additional_fields.push({...additional_field})
        } else {
          if (!state.form.additional_fields[existingIndex].required && additional_field.required) {
            state.form.additional_fields[existingIndex].required = true;
          }
        }
      })
    },
    setRegistrationPresentation: (state, presentation) => {
      state.presentation = presentation;
    },
    clearRegistrationFormValues: (state) => {
      state.form_values = {};
    },
    setRegistrationFormObjectValue: (state, {key, value, valueKey}) => {
      if (typeof state.form_values[key] !== 'object') {
        state.form_values[key] = {};
        state.form_values[key][valueKey] = value
      } else {
        state.form_values[key][valueKey] = value;
      }
    },
    setRegistrationFormValue: (state, {key, value, overwrite}) => {
      const shouldOverwrite = overwrite || (overwrite === undefined);

      if (shouldOverwrite || !state.form_values[key]) {
        state.form_values[key] = value;
      }
    },

    clearRegistrationAdditionalFields: (state) => {
      state.additional_fields = [];
    },

    purgeRegistrationAdditionalFields: (state) => {
      const knownLabels = state.form.additional_fields.map( (item) => item.label);
      state.additional_fields = [...state.additional_fields.filter( (field) => knownLabels.includes(field.label) )];
    },

    setRegistrationAdditionalFieldValue: (state, {key, value, overwrite}) => {
      const shouldOverwrite = overwrite || (overwrite === undefined);
      if (shouldOverwrite || !state.additional_fields[key]) {
        state.additional_fields[key].value = value;
      }
    },

    addRegistrationAdditionalField: (state, {field, overwrite}) => {
      const shouldOverwrite = overwrite || (overwrite === undefined);
      const key = state.additional_fields.findIndex( (item) => item.label === field.label);

      if (key !== -1) {
        if (shouldOverwrite || !state.additional_fields[key]) {
          state.additional_fields[key].value = field.value;
        }
        return;
      }

      state.additional_fields.push({...field});
    },

    clearSelectedTickets: (state) => {
      state.selected_tickets.splice(0);
    },

    changeSelectedTicketQuantity: (state, {ticket, quantity}) => {
      const parsedQuantity = parseInt(quantity);

      // Remove the existing selected ticket
      state.selected_tickets = state.selected_tickets.filter(selected_ticket => selected_ticket.ticket_id !== ticket.id);

      // Add back the selected ticket, if we have a quantity
      if (parsedQuantity) {
        state.selected_tickets.push({
          ticket_id: ticket.id,
          name: ticket.name,
          price: ticket.price,
          quantity: parsedQuantity,
        });
      }
    },

    clearSelectedProducts: (state) => {
      state.selected_products.splice(0);
    },
    changeSelectedProductQuantity: (state, {product, quantity}) => {
      const parsedQuantity = parseInt(quantity);

      state.selected_products = state.selected_products.filter(selectedProduct => selectedProduct.product_for_sale_id !== product.id);

      if (parsedQuantity) {
        state.selected_products.push({
          product_for_sale_id: product.id,
          name: product.productVariant.name,
          price: product.productVariant.price,
          quantity: parsedQuantity,
        });
      }
    },

    clearBuyTicketsError: (state) => {
      state.error = null;
    },

    setBuyTicketsError: (state, error) => {
      state.error = error;
    },

    addBuyTicketsValidationError: (state, {key, message}) => {
      if (!(state.error instanceof ValidationError)) {
        state.error = new ValidationError();
      }

      state.error.add(key, message);
    },

    clearProductsForSale: (state) => {
      state.products_for_sale.splice(0);
    },

    addProductsForSale: (state, productsForSale) => {
      state.products_for_sale.push(...(productsForSale.filter( (productForSale) => !productForSale.productVariant?.deleted_at )));
    },

    clearTicketsForSale: (state) => {
      state.tickets_for_sale.splice(0);
    },

    addTicketsForSale: (state, ticketsForSale) => {
      state.tickets_for_sale.push(...ticketsForSale);
    },

  },
  actions: {
    clearBuyTickets: ({commit}) => {
      commit('clearBuyTicketsError')
      commit('clearPaidOrders')
      commit('clearProductsForSale')
      commit('clearRegistrationFormValues')
      commit('clearSelectedProducts')
      commit('clearSelectedTickets')
      commit('clearTicketsForSale')
      commit('clearTermsMon')
    },
    toggleSelectedTicket: ({commit}, {ticket, checked}) => {
      commit('changeSelectedTicketQuantity', {ticket, quantity: checked ? 1 : 0});
    },
    toggleSelectedProduct: ({commit}, {product, checked}) => {
      commit('changeSelectedProductQuantity', {product, quantity: checked ? 1 : 0});
    },
    fetchPaidOrdersForEvent: async ({commit}, eventId) => {
      const response = await apolloClient.query({
        query: GET_ORDERS_FOR_EVENT,
        fetchPolicy: 'network-only',
        variables: {
          event_id: eventId,
        },
      });

      const paidOrders = response.data.getOrdersForEvent.filter( (order) => {
        return [StripeStatus.paid, StripeStatus.partially_refunded].includes(order.stripe_status)
      } );

      commit('addPaidOrders', paidOrders);

      return paidOrders;
    },

    fetchPaidOrdersForEvents: async ({commit}, eventIds) => {
      const response = await apolloClient.query({
        query: GET_ORDERS_FOR_EVENTS,
        fetchPolicy: 'network-only',
        variables: {
          event_ids: eventIds,
        },
      });

      const paidOrders = response.data.getOrdersForEvents.filter( (order) => {
        return [StripeStatus.paid, StripeStatus.partially_refunded].includes(order.stripe_status)
      } );

      commit('addPaidOrders', paidOrders);

      return paidOrders;
    },

    checkoutCompetition: ({state}, {
      competition_id
    }) => {
      return apolloClient.mutate({
        mutation: CHECKOUT_COMPETITION,
        variables: {
          data: {
            competition_id,
            tickets: state.selected_tickets.map( ({ticket_id, quantity}) => {
              return { ticket_id, quantity }
            } ),
            products: state.selected_products.map( ({product_for_sale_id, quantity}) => {
              return { product_for_sale_id, quantity }
            } ),
            additional_values: state.additional_fields,
            form_submission: {
              ...state.form_values,
              which_bike: JSON.stringify(state.form_values.which_bike),
              which_federation: JSON.stringify(state.form_values.which_federation),
              which_sport: JSON.stringify(state.form_values.which_sport),
            }
          }
        },
      })
    },
    updatePaymentMethodForOrder: async (context, {order_id, payment_method}) => {
      const result = await apolloClient.mutate({
        mutation: UPDATE_PAYMENT_METHOD_FOR_ORDER,
        variables: {
          id: order_id,
          payment_method,
        },
      });

      return result.data.updatePaymentMethodForOrder
    },
    validateAdditionalFields:( {state, commit}) => {
      // Premature return
      state.additional_fields.map(additionalField => {
        if((!additionalField.value) && additionalField.required) {
          commit('addBuyTicketsValidationError', {
            key: additionalField.label,
            message: getI18n().t('validation_required', {
              attribute: additionalField.label
            })
          })
        }
      });
    },

    /**
     *
     * @param dispatch
     * @param {Object} [opt={clear: true, overwrite: false}]
     * @param {boolean} [opt.clear=true] - Clear all form values
     * @param {boolean} [opt.overwrite=false] = Overwrite existing values
     * @returns {Promise<void>}
     */
    hydrateRegistrationForm: async ({dispatch}, opt = {}) => {
      const options = {clear: true, overwrite: false, ...opt};
      await dispatch('hydrateFormValues', options);
      await dispatch('hydrateAdditionalFields', options);
    },

    hydrateFormValues: ({state, commit, rootGetters}, opt = {}) => {
      const options = {clear: true, overwrite: false, ...opt};

      if (options.clear) {
        commit('clearRegistrationFormValues');
      }

      const user = rootGetters[CURRENT_USER_GETTER];

      Object.entries(state.presentation).forEach((form_field) => {
        if(state.form.profile_fields[form_field[0]] && state.form.profile_fields[form_field[0]].toggled) {
          form_field[1].fields.forEach(field => {
            switch(field.type) {
              case 'nationalities':
                commit('setRegistrationFormValue', {key: form_field[0], value: user[field.userKey]?.id, overwrite: options.overwrite})
                break;
              case 'bloodtype':
                commit('setRegistrationFormValue', {key: form_field[0], value: user[field.userKey]?.id, overwrite: options.overwrite})
                break;
              case 'bike':
                if(user[field.userKey] && user[field.userKey].length) {
                  const mappedValues = user[field.userKey].map(createPivotable)

                  commit('setRegistrationFormValue', {key: form_field[0], value: mappedValues, overwrite: options.overwrite})
                  break;
                }

                commit('setRegistrationFormValue', {key: form_field[0], value: null, overwrite: options.overwrite})
                break;
              case 'doctor':
                commit('setRegistrationFormValue', {
                  key: form_field[0],
                  value: {
                    name: user.doctor_name,
                    phone: user.doctor_tel
                  },
                  overwrite: options.overwrite
                });
                break;
              case 'emergency':
                commit('setRegistrationFormValue', {
                  key: form_field[0],
                  value: {
                    name: user.contact_person_name,
                    phone: user.contact_person_tel
                  },
                  overwrite: options.overwrite
                });
                break;
              case 'multi-select':
                if(user[field.userKey] && user[field.userKey].length) {
                  const mappedValues = user[field.userKey].map(createPivotable)

                  commit('setRegistrationFormValue', {key: form_field[0], value: mappedValues, overwrite: options.overwrite})
                  break;
                }

                commit('setRegistrationFormValue', {key: form_field[0], value: null, overwrite: options.overwrite})
                break;
              default:
                commit('setRegistrationFormValue', {
                  key: form_field[0],
                  value: user[field.userKey],
                  overwrite: options.overwrite
                })
            }
          })
        }
      })
    },
    hydrateAdditionalFields: ({state, commit}, opt = {}) => {
      const options = {clear: true, overwrite: false, ...opt};

      if (options.clear) {
        commit('clearRegistrationAdditionalFields')
      } else {
        // Otherwise, we will purge unused fields
        commit('purgeRegistrationAdditionalFields')
      }

      state.form.additional_fields.forEach( additionalField => {
        commit('addRegistrationAdditionalField', {
          field: {
            ...additionalField,
            value: ''
          }, overwrite: options.overwrite})
      });
    },

  },
}
