import Vue from 'vue'
import {IonicVueRouter} from '@ionic/vue'
import store from '../store';
import { routeNames } from './config';
import MiddlewareHandler from './middleware/MiddlewareHandler'
import { GET_USER_BY_TOKEN_ACTION, REFRESH_EXPIRED_ACCESS_TOKEN_ACTION } from "@/store/store-actions";
import { CURRENT_USER_GETTER, GET_APP_SHOULD_RELOAD, GET_NEW_VERSION_AVAILABLE } from "@/store/store-getters";
import { SHOULD_UPDATE_ACTION } from '../store/store-actions';

if(process.env.NODE_ENV !== 'test') {
  Vue.use(IonicVueRouter)
}

const routes = [
  {
    path: '/',
    name: routeNames.HOME,
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '/news',
        name: 'news',
        components: {
          news: () => import(/* webpackChunckName: "news" */ '../views/news/Index')
        }
      },

      {
        path: '/subscribed',
        name: routeNames.SUBSCRIBED,
        components: {
          subscribed: () => import(/* webpackChunckName: "subscribed" */ '../views/Subscribed.vue')
        }
      },
      {
        path: 'events',
        name: routeNames.EVENTS,
        components: {
          events: () => import(/* webpackChunckName: "events" */ '../views/events/Index.vue')
        }
      },
      {
        path: '/events/create/:facility?',
        name: routeNames.CREATE_EVENT,
        components: {
          events: () => import(/* webpackChunckName: "events" */ '../views/events/Create.vue')
        },
        props: true,
        meta: {
          middleware: [
            'auth',
            'can:events.create',
          ],
          allowAutoReloads: false,
        }
      },
      {
        path: '/events/create-competition/:facility?',
        name: routeNames.CREATE_COMPETITION,
        components: {
          events: () => import(/* webpackChunckName: "events" */ '../views/events/CreateCompetition.vue')
        },
        props: true,
        meta: {
          middleware: [
            'auth',
            'can:events.create',
          ],
          allowAutoReloads: false,
        }
      },
      {
        path: 'events/:id/edit',
        props: true,
        name: routeNames.EDIT_EVENT,
        components: {
          events: () => import(/* webpackChunckName: "events" */ '../views/events/Edit.vue')
        },
        meta: {
          middleware: [
            'auth',
          ],
          allowAutoReloads: false,
        }
      },
      {
        path: 'events/:id',
        props: true,
        name: routeNames.EVENT,
        components: {
          events: () => import(/* webpackChunckName: "events" */ '../views/events/Single.vue')
        }
      },
      {
        path: 'thanks',
        name: routeNames.THANKS,
        components: {
          thanks: () => import(/* webpackChunckName: "thanks" */ '../views/Thanks.vue')
        }
      },
      {
        path: '/facilities',
        name: routeNames.FACILITIES,
        components: {
          facilities: () => import(/* webpackChunckName: "facilities" */ '../views/facilities/Index.vue')
        },
      },
      {
        path: '/facilities/create',
        name: routeNames.MAKE_FACILITY,
        components: {
          facilities: () => import(/* webpackChunckName: "facilities" */ '../views/facilities/Create.vue')
        },
        meta: {
          middleware: [
            'auth',
            'can:facilities.create',
          ],
          allowAutoReloads: false,
        }
      },
      {
        path: 'facilities/:id',
        name: 'facility',
        components: {
          facilities: () => import(/* webpackChunckName: "facilities" */ '../views/facilities/Single.vue')
        },
      },

      {
        path: 'facilities/:id/top-up/thanks',
        name: routeNames.FACILITY_TOP_UP_THANKS,
        components: {
          thanks: () => import(/* webpackChunckName: "facilities" */ '../views/facilities/TopUpThanks.vue')
        },
      },

      {
        path: '/profile',
        name: routeNames.PROFILE,
        components: {
          profile: () => import(/* webpackChunckName: "profile" */ '../views/Profile')
        },
        meta: {
          middleware: [
            'auth',
          ]
        }
      },
      {
        path: 'error-403',
        name: routeNames.NOT_ACCESS,
        components: {
          'error-403': () => import(/* webpackChunckName: "error-403" */ '../views/NotAuthorized.vue'),
        }
      },
      {
        path: 'error-404',
        name: routeNames.NOT_FOUND,
        components: {
          'error-404': () => import(/* webpackChunckName: "error-404" */ '../views/NotFound.vue'),
        }
      },
    ]
  },
  {
    path: '/search',
    name: routeNames.SEARCH,
    component: () => import(/* webpackChunckName: "search" */ '../views/search/Index.vue'),
  },
  {
    path: '/facilities/:id/edit',
    name: routeNames.EDIT_FACILITY,
    component: () => import(/* webpackChunckName: "facilities" */ '../views/facilities/Edit.vue'),
    props: true,
    meta: {
      middleware: [
        'auth',
        'can:facilities.edit,{id}',
      ],
      allowAutoReloads: false
    }
  },
  {
    path: '/facilities/:id/upcoming-events',
    name: routeNames.FACILITY_UPCOMING_EVENTS,
    component: () => import(/* webpackChunckName "facilities" */ '../views/facilities/AllUpcomingEvents.vue'),
    props: true,
  },
  {
    path: '/facilities/:id/passed-events',
    name: routeNames.FACILITY_PASSED_EVENTS,
    component: () => import(/* webpackChunckName "facilities" */ '../views/facilities/AllPassedEvents.vue'),
    props: true,
  },
  {
    path: '/facilities/:id/advertisements/:advertisementId',
    name: routeNames.FACILITY_ADVERTISEMENT,
    component: () => import(/* webpackChunckName "facilities" */ '../views/facilities/tabs/advertisements/AdvertisementDetail.vue'),
    props: true,
  },
  {
    path: '/facilities/:id/members-bulk-edit',
    name: routeNames.FACILITY_MEMBERS_BULK_EDIT,
    component: () => import(/* webpackChunckName "facilities" */ '../views/facilities/MembersBulkUpdate.vue'),
    props: true,
  },
  {
    path: '/facilities/:id/payouts/:payoutId',
    name: routeNames.FACILITY_PAYOUTS,
    props: true,
    component: () => import(/* webpackChunckName "facilities" */ '../views/facilities/tabs/payouts/PayoutDetails.vue'),
    meta: {
      middleware: [
        'auth',
        'can:facilities.payout,{id}',
      ]
    }
  },
  {
    path: '/facilities/:id/transfers/:transferId',
    name: routeNames.FACILITY_TRANSFERS,
    props: true,
    component: () => import(/* webpackChunckName "facilities" */ '../views/facilities/tabs/transfers/TransferDetails.vue'),
    meta: {
      middleware: [
        'auth',
        'can:facilities.transfer,{id}',
      ]
    }
  },
  {
    path: '/news/:id',
    name: routeNames.NEWS_SINGLE,
    component: () => import(/* webpackChunkName: "news" */ '../views/news/Single')
  },
  {
    path: "/check-in/training",
    name: routeNames.CHECKIN_TRAINING,
    component: () => import(/* webpackChunkName: "checkin" */ '../views/checkin/Training.vue'),
    meta: {
      middleware: [
        'auth',
      ]
    }
  },
  {
    path: '/stripe-connect',
    name: routeNames.STRIPE_CONNECT,
    component: () => import(/* webpackChunckName: "stripe" */ '../views/StripeConnect.vue'),
    meta: {
      allowAutoReloads: false
    }
  },
  {
    path: '/login',
    name: routeNames.LOGIN,
    component: () => import(/* webpackChunckName: "login" */ '../views/Login.vue'),
    props: true
  },
  {
    path: '/register',
    name: routeNames.REGISTER,
    component: () => import(/* webpackChunckName: "login" */ '../views/Register.vue'),
    meta: {
      allowAutoReloads: false
    }
  },
  {
    path: '/scanner',
    name: routeNames.SCANNER,
    component: () => import(/* webpackChunckName: "scanner" */ '../views/Scanner.vue'),
    meta: {
      middleware: [
        'auth',
        'checkin',
      ]
    }
  },
  {
    path: '/checkedin-list',
    name: routeNames.CHECKED_IN_LIST,
    component: () => import(/* webpackChunckName: "checkedinlist" */ '../views/checkin/CheckedInList.vue'),
    meta: {
      middleware: [
        'auth',
        'checkin',
      ],
      allowAutoReloads: false
    }
  },
  {
    path: '/check-in',
    name: routeNames.CHECKIN,
    component: () => import(/* webpackChunckName: "checkin" */ '../views/checkin/CheckIn.vue'),
    meta: {
      middleware: [
        'auth',
      ]
    }
  },
  {
    path: '/check-in/tickets/:id',
    name: routeNames.TICKET_CHECKIN,
    component: () => import(/* webpackChunckName: "checkin" */ '../views/checkin/Ticket.vue'),
    meta: {
      middleware: [
        'auth',
      ]
    }
  },
  {
    path: "/password-forgotten",
    name: "password-forgotten",
    component: () => import(/* webpackChunckName "login" */ '../views/login/ForgotPassword.vue')
  },
  {
    path: '/resend-verification-mail',
    name: routeNames.RESEND_VERIFICATION_MAIL,
    component: () => import(/* webpackChunckName "login" */ '../views/login/ResendVerificationMail.vue')
  },
  {
    path: "/choose-new-password",
    name: routeNames.CHOOSE_NEW_PASSWORD,
    component: () => import(/* webpackChunckName "login" */ '../views/login/NewPassword.vue'),
    meta: {
      allowAutoReloads: false
    }
  },
  {
    path: "/email-verification-success",
    name: routeNames.EMAIL_VERIFICATION_SUCCESS,
    component: () => import(/* webpackChunckName "login" */ '../views/EmailVerificationSuccess.vue'),
  },
  {
    path: "/profile/orders",
    name: routeNames.PROFILE_ORDERS,
    component: () => import(/* webpackChunckName "profile" */ '../views/profile/Orders.vue')
  },
  {
    path: "/profile/change-information",
    name: routeNames.PROFILE_CHANGE_INFORMATION,
    component: () => import(/* webpackChunckName "profile" */ '../views/profile/ChangeInformation.vue')
  },
  {
    path: '/profile/managed-facilities',
    name: routeNames.MANAGED_FACILITIES,
    component: () => import(/* webpackChunckName: "profile" */ '../views/profile/ManagedFacilities.vue'),
    meta: {
      middleware: [
        'auth',
      ]
    }
  },
  {
    path: '/'
  },
]


const router = new IonicVueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/**
 * Handles app state changes and forced reloads before attempting to navigate to the next route
 */
router.beforeEach( (to, from, next) => {
  if (store.getters[GET_NEW_VERSION_AVAILABLE] || store.getters[GET_APP_SHOULD_RELOAD]) {
    window.location.replace(to.fullPath)
    return false;
  }
  next()
})

/**
 * Ensures that App updates and user tokens are updated before route changes
 */
router.beforeEach(async (to, from, next) => {
  await store.dispatch(SHOULD_UPDATE_ACTION);
  await store.dispatch(REFRESH_EXPIRED_ACCESS_TOKEN_ACTION);
  await store.dispatch(GET_USER_BY_TOKEN_ACTION);

  const handled = await MiddlewareHandler.handle({from, to, next, router});
  if (handled) {
      return;
  }

  if (to.name == routeNames.LOGIN && store.getters[CURRENT_USER_GETTER]) {
    next({
      name: routeNames.HOME,
    });
  }

  next()
})

/**
 * This fixes a certain issue with ionic pages remain invisible
 */
router.afterEach( () => {
  setTimeout( () => {
    const invisiblePage = document.getElementsByClassName('ion-page-invisible')[0];

    if (invisiblePage) {
      invisiblePage.classList.remove('ion-page-invisible')
    }
  }, 200);
})

export default router
