import allMiddleware from "@/router/middleware";

/**
 * @class MiddlewareHandler
 *
 * Allows us to define middleware for our application that handles conditions to join a route
 * Middleware available: auth, can, checkin ...
 */
class MiddlewareHandler {

    /**
     * Handle the route transition
     *
     * @param from From route
     * @param to To route
     * @param next next() handler
     * @param router The router instance
     * @returns {Promise<boolean>}
     */
    async handle({from, to, next, router}) {

        if (to?.meta?.middleware) {
            return await this.runMiddlewares({from, to, next, router});
        }

    }

    /**
     * Run all the middlewares on the `to` route
     *
     * @param from From route
     * @param to To route
     * @param next next() handler
     * @param router The router instance
     *
     * @returns {Promise<boolean>} Will return: TRUE when handled, FALSE if unhandled
     */
    async runMiddlewares({from, to, next, router}) {
        const middlewares = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];

        const context = {
            from,
            to,
            next,
            router,
        };

        let handled = false;

        for (let i in middlewares) {
            const middleware = this.parseMiddleware(middlewares[i]);

            const result = await allMiddleware[middleware.name].handle({...context, params: middleware.params});

            // If the middleware returns FALSE, we handle it by calling next(false)
            if (result === false) {
                handled = true;
                next(false);
                break;
            }

            // If the middleware returns TRUE, we do not call next
            if (result === true) {
                handled = true;
                break;
            }

            // If the middleware returns an object, we will call next(Object result)
            if ( result instanceof Object ) {
                next(result);
                handled = true;
                break;
            }

            // If the middleware returns a string, we will call next(String result)
            if ( typeof result === 'string') {
                next(result);
                handled = true;
                break;
            }

        }

        return handled;
    }

    /**
     * Parses an abstract representation of the middleware configuration and returns name and params
     *
     * @param Object|String middlewareConfiguration Either { name, params } or "name:param1,param2" representation
     * @returns {{name: string, params: string|Object}|*}
     */
    parseMiddleware(middlewareConfiguration) {

        if (typeof middlewareConfiguration === 'string') {
            const [ name, params ] = middlewareConfiguration.split(':', 2);
            return {
                name,
                params
            }
        }

        return middlewareConfiguration;
    }

}

export default new MiddlewareHandler();
