import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from "@sentry/tracing";
import Vue from 'vue';
import { IGNORED_ERRORS_REGEX } from "@/configs";

export const SentryService = {
  init() {
    if(process.env.VUE_APP_SENTRY_DSN) {
      Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        release: process.env.VUE_APP_SENTRY_RELEASE,
        maxValueLength: 3500,
        integrations: [
          new VueIntegration({
            Vue,
            logErrors: true,
          }),
          new Integrations.BrowserTracing(),
        ],
        tracesSampleRate: 1.0,
        ignoreErrors: IGNORED_ERRORS_REGEX
      });
    }
  }
}
