import moment from 'moment-timezone';
import { APP_DEFAULTS } from "@/configs";
import { orderBy } from "lodash";
import { getI18n } from "@/translations";
export const eventSoldOut = (event) => event.total_capacity && event.attendee_count >= event.total_capacity;
export const noTicketsAvailable = (event) => {
  return !event.tickets.some(ticket => {
    if(ticket.quantity_available === 0) {
      return true
    }

    if(ticket.quantity_available > ticket.sold_tickets_count) {
      return true;
    }

    return false
  });
}
export const noProductsAvailable = (event) => {
  return !event.products_for_sale.some(product => {
    if(product.quantity_available === 0) {
      return true
    }

    if(product.quantity_available > product.sold_orders_count) {
      return true;
    }

    return false
  });
}
export const eventIsOpen = (event) => moment.tz(event.opens_at, APP_DEFAULTS.DEFAULT_TIMEZONE).isSameOrBefore(moment());
export const eventHasPassed = (event) => moment.tz(event.ends_at, APP_DEFAULTS.DEFAULT_TIMEZONE).isBefore(moment());
export const eventIsCancelled = (event) => !!event.cancelled_at;

export const quantityAvailable = (ticket) => {
  if (ticket.quantity_available === 0) {
    return Number.MAX_SAFE_INTEGER;
  }

  if (ticket.sold_tickets_count >= ticket.quantity_available) {
    return 0;
  }

  return ticket.quantity_available - ticket.sold_tickets_count;
};
export const stockAvailable = (productForSale) => {
  if (productForSale.quantity_available === 0) {
    return Number.MAX_SAFE_INTEGER;
  }

  if (productForSale.sold_orders_count >= productForSale.quantity_available) {
    return 0;
  }

  return productForSale.quantity_available - productForSale.sold_orders_count;
};

export const groupOrderablesByEvent = function (orders, options = {}) {
  const removeRefunded = options.removeRefunded ?? false;
  const eventGroups = {};

  let orderables = [];
  const i18n = getI18n();

  orders.forEach( (order) => {
    orderables = orderables.concat(order.tickets).concat(order.products);
  })

  if (removeRefunded) {
    orderables = orderables.filter((orderable) => !orderable.pivot?.refunded_at )
  }

  orderables.forEach( (orderable) => {
    if (!eventGroups[orderable.event.id]) {
      eventGroups[orderable.event.id] = {
        event: orderable.event,
        groupedTickets: {},
        groupedProducts: {},
      }
    }

    const appendix = (orderable.pivot?.refunded_at ? ` (${i18n.t('refunded')})` : '');

    if (orderable.__typename === 'Ticket') {
      const name = orderable.name + appendix;

      if (!eventGroups[orderable.event.id].groupedTickets[name]) {
        eventGroups[orderable.event.id].groupedTickets[name] = {
          name,
          items: [],
          is_refunded: orderable.pivot?.refunded_at ? true : false,
        }
      }
      eventGroups[orderable.event.id].groupedTickets[name].items.push(orderable);
    } else {
      const name = orderable.productVariant.name + appendix;

      if (!eventGroups[orderable.event.id].groupedProducts[name]) {
        eventGroups[orderable.event.id].groupedProducts[name] = {
          name,
          items: [],
          is_refunded: orderable.pivot?.refunded_at ? true : false,
        }
      }
      eventGroups[orderable.event.id].groupedProducts[name].items.push(orderable);
    }

  })

  return Object.values(orderBy(eventGroups, (group) => group.event.held_at));
}