import * as FullStory from '@fullstory/browser';
import Vue from 'vue';

export const FullstoryService = {
  init() {
    FullStory.init({ 
      orgId: 'YXES8',
    });
    Vue.prototype.$FullStory = FullStory;
  }
}