import { Preferences } from "@capacitor/preferences";

class AuthStorage {

  async setItem(tokenName, value) {
    await Preferences.set({key: tokenName, value});
  }

  async getItem(tokenName) {
    const { value } = await Preferences.get({key: tokenName})

    return value;
  }

  async removeItem(tokenName) {
    await Preferences.remove({key: tokenName})
  }

}

export default new AuthStorage();