<template>
  <ion-list>
    <ion-list-header>
      <strong>{{ header }}</strong>
    </ion-list-header>
    <ion-item class="mb-4" v-if="message">
      <ion-text color="moto">{{ message }}</ion-text>
    </ion-item>
    <ion-item button v-for="option in options" :key="option.key" @click="selectOption(option)">
      {{ option.label }}
      <ion-icon v-if="isSelected(option)" slot="end" name="checkmark" />
    </ion-item>
    <ion-button
      v-if="showAbort"
      expand="full"
      button
      role="cancel"
      @click="cancel"
      class="mt-4">
      {{ $t('abort') }}
    </ion-button>
  </ion-list>
</template>

<script>
    export default {
      props: {
        header: {
          type: String,
        },
        message: {
          type: String,
        },
        options: {
          type: Array,
          required: true,
        },
        selectedOption: {
          type: Object,
          required: false,
        },
        showAbort: {
          type: Boolean,
          default: false,
        },
        onChoice: {
          type: Function,
          required: true,
        },
        onCancel: {
          type: Function,
          required: true,
        }
      },
      methods: {
        isSelected(option) {
          if (!this.selectedOption) {
            return false;
          }

          return option.key == this.selectedOption.key;
        },
        selectOption(option) {
          this.onChoice(option);
        },
        cancel() {
          this.onCancel();
        },
      }
    }
</script>
