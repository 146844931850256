import { gql } from '@apollo/client';
import {
    FACILITY_FRAGMENT,
    SINGLE_FACILITY_FRAGMENT,
    PRODUCT_FRAGMENT,
    PRODUCT_VARIANT_FRAGMENT_WITH_RELATIONS,
    PRODUCT_VARIANT_FRAGMENT,
    PRODUCT_FAMILY_FRAGMENT,
    PRODUCT_FRAGMENT_WITH_RELATIONS, EVENT_PRODUCTS_FOR_SALE, ORDERABLE_PIVOT_FRAGMENT, ORDER_FOR_EVENT_FRAGMENT
} from '@/graphql/fragments';
import { EVENT_FRAGMENT, USER_FRAGMENT } from "./fragments";

export const GET_ORDERED_EVENTS = gql`query getTicketsForEvent{
        getTicketsForEvent {
            id
            title
            held_at
            attendee_ticket_count
        }
}`

export const GET_ADS = gql`query getAds($slots: [AdvertisementSlot!]!) {
    getAds(slots: $slots) {
        uuid
        image_url
        redirect_url
        color
    }
}`

export const GET_EVENTS_ORGANIZERS = gql`query events{
    getEventsForOrganizers {
        id
        title
        held_at
        total_capacity
        ticket_count
    }
}`

export const GET_TICKETS_FOR_ORDER = gql`query getTicketForEvent($id: ID!){
    getTicketForEvent(id: $id) {
        id
        event {
            title
        }
        user {
            first_name
            last_name
        }
        qr_codes {
            id
            qr_code
        }
    }
}`

export const GET_UPCOMING_FACILITY_EVENTS = gql`query getUpcomingFacilityEvents($facility_id: ID!, $filter_by: UpcomingEventFilterEnum, $first: Int!, $page: Int!) {
    getUpcomingFacilityEvents(facility_id: $facility_id, filter_by: $filter_by, first: $first, page: $page) {
        data {
            ...event
        }
        paginatorInfo {
            hasMorePages
        }
    }
}
${EVENT_FRAGMENT}`

export const GET_PASSED_FACILITY_EVENTS = gql`query getPassedFacilityEvents($facility_id: ID!, $filter_by:PassedEventFilterEnum, $first: Int!, $page: Int!) {
    getPassedFacilityEvents(facility_id: $facility_id, filter_by: $filter_by, first: $first, page: $page) {
        data {
            ...event
        }
        paginatorInfo {
            hasMorePages
        }
    }
}
${EVENT_FRAGMENT}`

export const GET_ALL_FACILITY_EVENTS = gql`query getAllFacilityEvents($facility_id: ID!, $filter_by: String, $first: Int!, $page: Int!) {
    getAllFacilityEvents(facility_id: $facility_id, filter_by: $filter_by, first: $first, page: $page) {
        data {
            ...event
        }
        paginatorInfo {
            hasMorePages
        }
    }
}
${EVENT_FRAGMENT}`

export const GET_FACILITY = gql`query facility($id: ID!){
    facility(id: $id) {
        ...singleFacility
    }
    
}
${SINGLE_FACILITY_FRAGMENT}`;

export const GET_USER = gql`query getUserByToken {
    getUserByToken {
        ...user
    }
}
${USER_FRAGMENT}
${FACILITY_FRAGMENT}
`

export const GET_ORDERS = gql`query orders($first: Int!, $page: Int) {
    orders(first: $first, page: $page) {
        data {
            id
            name
            seller {
                ...on Facility {
                    id
                    name
                }
                ...on User {
                    id
                    first_name
                }
            }
            tickets {
                id
                name
                event {
                    id
                    title
                    held_at
                }
                pivot {
                    ...orderablePivot
                }
            }
            products {
                id
                productVariant {
                    id
                    name
                }
                event {
                    id
                    title
                    held_at
                }
                pivot {
                    ...orderablePivot
                }
            }
            stripe_status
            rider_fee
            total_price
            refunded_amount
            created_at
            user_id
        }
        paginatorInfo {
            hasMorePages
        }
    }
}
${ORDERABLE_PIVOT_FRAGMENT}
`;

export const GET_ORDERS_FOR_EVENT = gql`query getOrdersForEvent($event_id: ID!) {
    getOrdersForEvent(event_id: $event_id) {
        ...orderForEvent
    }
}
${ORDER_FOR_EVENT_FRAGMENT}
`;

export const GET_ORDERS_FOR_EVENTS = gql`query getOrdersForEvents($event_ids: [ID!]) {
    getOrdersForEvents(event_ids: $event_ids) {
        ...orderForEvent
    }
}
${ORDER_FOR_EVENT_FRAGMENT}
`;

export const GET_EDIT_EVENT = gql`query edit_event($id: ID!) {
    editEvent(id: $id) {
        id
        title
        text
        sports {
            id
            name
        }
        levels {
            id
            name
        }
        federations {
            id
            system_name
        }
        track_conditions {
            id
            name
        }
        location {
            id
            name
            lat
            long
            number
            city
            country
            state
            street
            zip_code
        }
        image
        checkout_form {
            id
        }
        forms {
            id
            type
            profile_fields
            additional_fields
        }
        opens_at
        held_at
        ends_at
        training_event {
            id
            training_in_groups
            trainer_available
        }
        total_capacity
        first_aid_available
        organizer {
            id
            name
            profile_image
            enabled_subscriptions {
                id
                name
            }
        },
        host_facility_id
        tickets {
            id
            name
            is_for_members
            is_required
            subscriptions {
                id
            }
            price
            quantity_available
            sold_tickets_count
            max_tickets_per_ticket
            requirements {
                type
                params
            }
        }
        products_for_sale {
            ...eventProductVariant
        }
        
        event_category {
            id
            name
        }

        competition_events {
            id
        }

    }
}
${EVENT_PRODUCTS_FOR_SALE}
${PRODUCT_FRAGMENT}
${PRODUCT_FAMILY_FRAGMENT}
${PRODUCT_VARIANT_FRAGMENT}
${PRODUCT_VARIANT_FRAGMENT_WITH_RELATIONS}`;

export const GET_EVENT = gql`query event($id: ID!) {
    event(id: $id) {
        id
        parent_id
        recurring_type
        title
        text
        location {
            id
            name
        }
        image
        checkout_form {
            id
        }
        forms {
            id
            type
            profile_fields
            additional_fields
        }
        is_open
        opens_at
        held_at
        ends_at
        cancelled_at
        total_capacity
        total_checkins
        host_facility {
            id
            name
        }
        organizer {
            id
            name
            stripe {
                id
            }
        }
        attendee_count
        event_category {
            id
            name
        }
        uses_third_party_ticket_provider
        third_party_ticket_provider_url
        tickets {
            id
            event_id
            name
            is_for_members
            is_required
            subscriptions {
                id
            }
            price
            quantity_available
            sold_tickets_count
            max_tickets_per_ticket
            requirements {
                type
                params
            }
        }
        products_for_sale {
            ...eventProductVariant
        }
    }
}
${EVENT_PRODUCTS_FOR_SALE}
${PRODUCT_VARIANT_FRAGMENT_WITH_RELATIONS}
${PRODUCT_VARIANT_FRAGMENT}
${PRODUCT_FAMILY_FRAGMENT}
${PRODUCT_FRAGMENT}
`

export const GET_COMPETITION_EVENTS = gql`query getCompetitionEvents($competition_event_id: ID!) {
    getCompetitionEvents(competition_event_id: $competition_event_id) {
        id
        parent_id
        recurring_type
        title
        text
        location {
            id
            name
        }
        image
        checkout_form {
            id
        }
        forms {
            id
            type
            profile_fields
            additional_fields
        }
        is_open
        opens_at
        held_at
        ends_at
        cancelled_at
        total_capacity
        total_checkins
        organizer {
            id
            name
            stripe {
                id
            }
        }
        host_facility {
            id
            name
        }
        attendee_count
        event_category {
            id
            name
        }
        uses_third_party_ticket_provider
        third_party_ticket_provider_url
        tickets {
            id
            event_id
            name
            is_for_members
            is_required
            subscriptions {
                id
            }
            price
            quantity_available
            sold_tickets_count
            max_tickets_per_ticket
            requirements {
                type
                params
            }
        }
        products_for_sale {
            ...eventProductVariant
        }
    }
}
${EVENT_PRODUCTS_FOR_SALE}
${PRODUCT_VARIANT_FRAGMENT_WITH_RELATIONS}
${PRODUCT_VARIANT_FRAGMENT}
${PRODUCT_FAMILY_FRAGMENT}
${PRODUCT_FRAGMENT}
`

export const GET_EVENTS = gql`query events($category: [CategoryType], $latitude: Float, $longitude: Float, $radius: Int, $begin_time: Date!, $end_time: Date, $first: Int!, $page: Int,) {
    events(first: $first, page: $page, category: $category, latitude: $latitude, longitude: $longitude, radius: $radius, begin_time: $begin_time, end_time: $end_time) {
        data {
            ...event
        }
        paginatorInfo {
            hasMorePages
        }
    }
}
${EVENT_FRAGMENT}`

export const GET_SINGLE_NEWS = gql`query newsItem($id: ID!) {
    newsItem(id: $id) {
        id
        title
        text
        image
        created_at
    }
}`

export const SEARCH_ADMINS = gql`query searchAdmins($search: String!, $facility_id: ID!) {
    searchAdmins(search: $search, facility_id: $facility_id) {
        id
        first_name
        last_name
        email
    }
}`

export const GET_FACILITIES = gql`query facilities( $type: FacilityTypeEnum, $latitude: Float, $longitude: Float, $first: Int!, $page: Int) {
    facilities(first: $first, page: $page, type: $type, latitude: $latitude, longitude: $longitude) {
        data {
            id
            profile_image
            name
        }
        paginatorInfo {
            hasMorePages
        }
    }
}`

export const GEOCODE = gql`query geocode($q: String!) {
    geocode(q: $q) {
        type
        name
        lat
        long
        state
        country
        city
        street
        number
        zip_code
    }
}`

export const GET_LOCATIONS = gql`query searchLocations($search: String!) {
    searchLocations(search: $search) {
        id
        name
        lat
        long
        state
        country
        city
        street
        number
        zip_code
    }
}`

export const GET_TRAINING_EVENTS = gql`query getTrainingEvents {
    getTrainingEvents {
        id
        title
        held_at
        total_capacity
        total_checkins
        ticket_count
    }
    
}`

export const GET_TRAINING_RIDER = gql`query getTrainingCheckIn($qrcode: String!, $event_id: ID!) {
    getTrainingCheckIn(qrcode: $qrcode, event_id: $event_id) {
        user {
            ...user
        }
        training_ticket {
            id
            name
            price
        }
        orders {
            id
            tickets {
                id
                name
                price
                event {
                    id
                    title
                    held_at
                }
                pivot {
                    ...orderablePivot
                }
            },
            products {
                id
                event {
                    id
                    title
                    held_at
                }
                productVariant {
                    id
                    name
                    price
                },
                pivot {
                    ...orderablePivot
                }
            }
        }
    }
}
${USER_FRAGMENT}
${FACILITY_FRAGMENT}
${ORDERABLE_PIVOT_FRAGMENT}
`

export const GET_NATIONALITIES = gql`query nationalities {
    nationalities {
        id
        country
    }
}`;

export const GET_BIKE_MAKES = gql`query bikeMakes {
    bikeMakes {
        id
        make
        bikes {
            id
            model
            build_year
        }
    }
}`

export const GET_BIKES = gql`query getBikes {
    getBikes {
        id
        build_year
        model
        bike_make {
            id
            make
        }
    }
}`

export const GET_FEDERATIONS = gql`query federations {
    federations {
        id
        system_name
    }
}`

export const GET_SPORTS = gql`query sports {
    sports {
        id
        name
    }
}`

export const GET_BLOOD_TYPES = gql`query bloodtypes {
    bloodtypes {
        id
        name
    }
}`

export const GET_EVENT_ATTENDEES = gql`query getEventRegistrations($event_id: ID!, $expand_competitions: Boolean) {
    getEventRegistrations(event_id: $event_id, expand_competitions: $expand_competitions) {
        id
        user {
            id
            first_name
            last_name
            email
        }
        submission {
            id
            submitted_values
            additional_values
        }
        order {
            id
            created_at
            total_price
            stripe_status
            is_partially_refundable
            tickets {
                id
                event {
                    id
                    title
                }
                name
                price
                pivot {
                    ...orderablePivot
                }
            }
            products {
                id
                event {
                    id
                    title
                }
                productVariant {
                    id
                    name
                    price
                }
                pivot {
                    ...orderablePivot
                }
            }
        }
        event {
            id
        }
    }
}
${ORDERABLE_PIVOT_FRAGMENT}
`

export const SEARCH_MEMBERS = gql`query searchMembers($search: String, $facility_id: ID!) {
    searchMembers(search: $search, facility_id: $facility_id) {
        id
        first_name
        last_name
        email
    }
}`


export const GET_NEWS = gql`query news($type: NewsTypeEnum, $page: Int, $first: Int!, $orderBy: [QueryNewsOrderByOrderByClause!]) {
    news(type: $type, first: $first, page: $page, orderBy: $orderBy) {
        data {
            id
            title
            user_id
            text
            image
            created_at
        }
        paginatorInfo {
            currentPage
            lastPage
            hasMorePages
        }
    }
}`

export const CHECKED_IN_RIDERS = gql`query getCheckedInRiders($event_id: ID!) {
    getCheckedInRiders(event_id: $event_id) {
        ...user
    }
}
${USER_FRAGMENT}
${FACILITY_FRAGMENT}`

export const GET_PAYOUTS = gql`query getPayouts($facility_id: ID!) {
    getPayouts(facility_id: $facility_id) {
        id
        amount
        currency
        status
        arrival_date
    }
}`

export const GET_ORDER_TRANSFERS = gql`query getOrderTransfers($facility_id: ID!, $first: Int!, $page: Int) {
    getOrderTransfers(first: $first, page: $page, facility_id: $facility_id) {
        data {
            id
            title
            type
            stripe_amount
            status
            created_at
        }
        paginatorInfo {
            hasMorePages
        }
    }
}`

export const GET_PAYOUT_DETAILS = gql`query getPayoutDetails($facility_id: ID!, $payout_id: String!, $cursor: String) {
    getPayoutDetails(facility_id: $facility_id, payout_id: $payout_id, cursor: $cursor) {
        has_more 
        data {
            id
            amount
            available_on
            currency
            type
        }
    }
}`;

export const GET_PAYOUT_SUMMARY = gql`query getPayoutSummary($facility_id: ID!, $payout_id: String!) {
    getPayoutSummary(facility_id: $facility_id, payout_id: $payout_id)
}`;

export const GET_ORDER_TRANSFER = gql`query getOrderTransfer($facility_id: ID!, $order_transfer_id: ID!) {
    getOrderTransfer(facility_id: $facility_id, order_transfer_id: $order_transfer_id) {
        id
        type
        title
        stripe_amount
        status
        created_at
        
        order {
            user {
                id
                email
                first_name
                last_name
                date_of_birth
            }
            seller {
                ...on Facility {
                    id
                    name
                }
                ...on User {
                    id
                    first_name
                }
            }
        }
        lineItems {
            id
            type
            name
            stripe_amount
            refunded_at
        }
    }
}`;

export const GET_FACILITY_SUBSCRIPTIONS = gql`query getFacilitySubscriptions($facility_id: ID!) {
    getFacilitySubscriptions(facility_id: $facility_id) {
        id
        name
        price
        is_publicly_orderable
        is_live
    }
}`

export const GET_ENABLED_FACILITY_SUBSCRIPTIONS = gql`query getEnabledFacilitySubscriptions($facility_id: ID!) {
    getEnabledFacilitySubscriptions(facility_id: $facility_id) {
        id
        name
        price
        is_publicly_orderable
        is_live
    }
}`

export const GET_FACILITY_MEMBERS = gql`query getFacilityMembers($facility_id: ID!) {
    getFacilityMembers(facility_id: $facility_id) {
        id
        became_member_at
        expires_at
        is_expired
        user {
            id
            first_name
            last_name
            email
        }
        subscription {
            id
            name
            is_live
        }
    }
}`

export const GET_ORDERABLE_PRODUCTS_FOR_EVENTS = gql`query getOrderableProductsForEvents($facility_id: ID!, $first: Int!, $page: Int) {
    getOrderableProductsForEvents(first: $first, page: $page, facility_id: $facility_id) {
        data {
            ...productWithRelations
        }
        paginatorInfo {
            hasMorePages
        }
    }
}
${PRODUCT_FRAGMENT_WITH_RELATIONS}
${PRODUCT_FRAGMENT}
${PRODUCT_VARIANT_FRAGMENT}
${PRODUCT_FAMILY_FRAGMENT}
`;

export const GET_PENDING_EMAIL_CHANGES = gql`query getPendingEmailChanges {
    getPendingEmailChanges {
        id
        email
        created_at
        expires_at
    }
}`

export const HAS_TFA_ENABLED = gql`query hasTFAEnabled($email: String) {
    hasTFAEnabled(email: $email)
}`

export const GET_TFA_SECRETS = gql`query getTFASecrets {
    getTFASecrets {
        qr_code
        secret
    }
}`

export const CHECK_EMAIL_IS_AVAILABLE = gql`query checkEmailIsAvailable($email: String) {
    checkEmailIsAvailable(email: $email)
}`

export const GET_FACILITY_ADS = gql`query getAdsByFacility($facility_id: ID!) {
    getAdsByFacility(facility_id: $facility_id) {
        id
        uuid
        title
        image_url
        color
        impressions_count
        clicks_count
    }
}`

export const GET_AD_EVENTS_BY_FACILITY = gql`query getAdEventsByFacility($first: Int!, $page: Int!, $facility_id: ID!, $ad_id: ID!) {
    getAdEventsByFacility(first: $first, page: $page, facility_id: $facility_id, ad_id: $ad_id) {
        data {
            id
            event_type
            created_at
        }
        paginatorInfo {
            hasMorePages
        }
    }
}`
export const GET_AD_ANALYTICS = gql`query getAdAnalytics( $facility_id: ID!, $ad_id: ID!, $event_type: AdEventType!, $start_time: DateTime!, $end_time: DateTime!, $unit: AdAnalyticsUnit!) {
    getAdAnalytics(facility_id: $facility_id, ad_id: $ad_id, event_type: $event_type, start_time: $start_time, end_time: $end_time, unit: $unit) {
        label
        start_time
        end_time
        value
    }
}`

export const GET_BALANCE = gql`query getBalance($facility_id: ID!) {
    getBalance(facility_id: $facility_id) {
        stripe_balance
        pending_refunds_amount
        pending_refunds_quantity
    }
}`


export const GET_TOP_UPS = gql`query getTopUps($facility_id: ID!) {
    getTopUps(facility_id: $facility_id) {
        id
        description
        user {
            id
            email
            first_name
            last_name
        }
        order {
            stripe_status
        }
        amount
        created_at
    }
}`

export const SEARCH_QUERY = gql`
  query GlobalSearch($search: String!, $type: String!) {
    globalSearch(search: $search, type: $type) {
      facilities {
        id
        name
        profile_image
      }
      news {
        id
        title
        type
        image
        created_at
      }
      events {
        ...event
      }
    }
  }
${EVENT_FRAGMENT}
`;

export const USER_SEARCH_HISTORY_QUERY = gql`
  query {
  getRecentSearches {
    id
    searchable_id
    searchable_type
    searchable {
      __typename
        ... on Facility {
          id
          name
          profile_image
        }
        ... on News {
          id
          title
          type
          image
          created_at
        }
      ... on Event {
        ...event
      }
    }
  }
}
${EVENT_FRAGMENT}
`;
