import store from "@/store";
import {routeNames} from "@/router/config";
import { GET_EVENT_ID_GETTER } from "@/store/store-getters";

/**
 * Checkin middleware
 * Ensures that a user has checked in to an event, otherwise redirect to the CHECKIN route
 */
class Checkin {

    handle({next}) {

        if(!store.getters[GET_EVENT_ID_GETTER]) {
            next({ name: routeNames.CHECKIN })
            return true;
        }

    }

}

export default new Checkin()
