
export default class FacilitiesPolicy {

  /**
   * Check edit permissions for the facility
   *
   * @param user
   * @param facility
   * @param args
   * @returns {boolean}
   */
  static edit(user, facility) {
    const facilityId = (facility instanceof Object) ? facility.id : facility;

    return user?.facilities?.some(userFacility => userFacility.id === facilityId);
  }

  /**
   * Check payout permissions for the facility
   *
   * @param user
   * @param facility
   * @param args
   * @returns {boolean}
   */
  static payout(user, facility) {
    const facilityId = (facility instanceof Object) ? facility.id : facility;

    return user?.facilities?.some(userFacility => userFacility.id === facilityId);
  }

  /**
   * Check transfer permissions for the facility
   *
   * @param user
   * @param facility
   * @param args
   * @returns {boolean}
   */
  static transfer(user, facility) {
    const facilityId = (facility instanceof Object) ? facility.id : facility;

    return user?.facilities?.some(userFacility => userFacility.id === facilityId);
  }

 /**
   * Check create permissions for the facility
   *
   * @param user
   * @param facility
   * @param args
   * @returns {boolean}
   */
  static create(user) {
    return !!user;
  }

}
