import PopOverChoice from "@/components/popovers/PopOverChoice";
import LoadingModal from "@/components/modals/LoadingModal";

export const TOAST_SUCCESS = 'success';
export const TOAST_DANGER = 'danger';
export const TOAST_WARNING = 'warning';

export async function showToast(message, color = 'success', duration = 3000, extraOptions = {}) {
  const toast = await this.$ionic.toastController.create({
    ...extraOptions,
    color: color,
    duration: duration,
    message: message
  });

  await toast.present();
}

export const formattedGraphQLErrorMessage = (errorMessage) => {
  return errorMessage.replace('GraphQL error:', '').trim();
}

export const SAFE_LINK_REL = 'noopener noreferrer nofollow';

/**
 * Returns a valid website url based on the input value
 *
 * @param websiteUrl
 * @returns {string|null} A valid website url, or null if the url is invalid
 */
export function validWebsiteURL(websiteUrl) {

  if (!websiteUrl || !websiteUrl.trim()) {
    return null;
  }

  let url = websiteUrl.trim();

  // Prefix with https
  if (!url.match('^http(s)?://')) {
    url = `https://${url}`;
  }

  // If we cannot parse the url, it is still not a valid url
  try {
    new URL(url);
  } catch (e) {
    return null;
  }

  return url;
}

export const confirm = async function (header, message) {
  return new Promise( async (resolve) => {

    const alert = await this.$ionic.alertController.create({
      header,
      message,
      buttons: [
        {
          text: this.$t('cancel'),
          role: 'cancel',
          cssClass: 'danger',
          handler: () => {
            resolve(false);
          }
        },
        {
          text: this.$t('confirm'),
          handler: () => {
            resolve(true);
          },
        },
      ],
    });

    alert.onDidDismiss().then( () => resolve(false));

    await alert.present();
  });

}

export const choose = async function (header, message, options, {selectedOption = null, event = undefined, translucent = false, asAlert= false}) {
  return new Promise( async (resolve) => {

    let alert;
    let selectedKey = selectedOption?.key;

    if (asAlert) {
      const inputs = options.map( (option) => {
        return {
          type: 'radio',
          label: option.label,
          value: option.key,
          checked: selectedKey === option.key,
          handler: (input) => {
            selectedKey = input.value;
          }
        }
      })
       alert = await this.$ionic.alertController.create({
        header,
        message,
        inputs,
        buttons: [
          {
            text: this.$t('cancel'),
            role: 'cancel',
            cssClass: 'danger',
            handler: () => {
              resolve(false);
              return true;
            }
          },
          {
            text: this.$t('ok'),
            handler: () => {
              resolve(options.find(option => option.key === selectedKey));
              return true;
            },
          },
        ],
      });
    } else {
      alert = await this.$ionic.popoverController.create({
        component: PopOverChoice,
        componentProps: {
          parent: this,
          propsData: {
            header,
            message,
            options,
            selectedOption,
            showAbort: false,
            onChoice: (option) => {
              resolve(option);
              alert.dismiss();
            },
            onCancel: () => {
              resolve(false);
              alert.dismiss();
            },
          },
        },
        translucent,
        event,
      });
    }

    alert.onDidDismiss().then( () => resolve(false));

    await alert.present();
  });

}

export const showLoading = async function (message) {
  const modal = await this.$ionic.modalController
    .create({
      component: LoadingModal,
      componentProps: {
        parent: this,
        propsData: {
          title: message ?? this.$t('loading')
        },
      },
      backdropDismiss: false,
      showBackdrop: true,
      keyboardClose: false,
    });

  await modal.present();

  return modal;
}