export default class EventsPolicy {

  /**
   *
   * @param user
   * @param event
   * @param args
   * @returns {boolean}
   */
  static create(user) {
    let allowed = false;

    if (user?.facilities && user?.facilities.length) {
      allowed = true
    }

    return allowed;
  }

  static edit(user, event) {
    if (!event) {
      // eslint-disable-next-line no-console
      console.warn('Empty events can not be edited');
      return false;
    }

    return user?.facilities?.some(userFacility => userFacility.id === event.organizer.id);
  }
}
