/**
 * Checks if the JWT Token is still valid.
 *
 * @param {string} jwt The Jwt string
 * @param {Number} earlyExpirationMinutes Jwt will be considered invalid X minutes before expiration
 * @returns {boolean} True if still valid, False if invalid
 * @constructor
 */
export const JWTIsValid = function (jwt, earlyExpirationMinutes = 10) {
  const parts = jwt.split('.');
  if (parts.length < 2) {
    return false;
  }

  // Ensure that the headers are correct
  try {
    JSON.parse(atob(parts[0]));
  } catch (e) {
    return false;
  }

  let body;
  try {
    body = JSON.parse(atob(parts[1]));
  } catch (e) {
    return false;
  }

  const now = Math.floor(new Date().getTime()/1000);

  return body.exp > (now + (earlyExpirationMinutes * 60) );
}
