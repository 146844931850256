<template>
  <ion-infinite-scroll :threshold="threshold" position="bottom" @ionInfinite="($event) => this.$emit('ionInfinite', $event)">
    <ion-infinite-scroll-content :loading-spinner="loadingSpinner" :loading-text="$t('loadingmore')" />
  </ion-infinite-scroll>
</template>

<script>
import { loadingSpinner } from '@/configs';

export default {
  props: {
    threshold: {
      type: String,
      default: '100px',
    }
  },
  data() {
    return {
      loadingSpinner
    }
  }
}
</script>