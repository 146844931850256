export const REFRESH_EXPIRED_ACCESS_TOKEN_ACTION = 'refreshExpiredAccessToken';
export const GET_USER_BY_TOKEN_ACTION = 'getUserByToken';
export const LOGIN_WITH_REFRESH_TOKEN_ACTION = 'loginWithRefreshToken';
export const NEW_PASSWORD_WITH_TOKEN_ACTION = 'newPasswordWithToken';
export const REQUEST_EMAIL_CHANGE_ACTION = 'requestEmailChange';
export const CONFIRM_EMAIL_CHANGE_ACTION = 'confirmEmailChange';
export const DELETE_EMAIL_CHANGE_ACTION = 'deleteEmailChange';
export const FETCH_PENDING_EMAIL_CHANGES_ACTION = 'fetchPendingEmailChanges';
export const LOGIN_USER_ACTION = 'loginUser';
export const UPDATE_USER_ACTION = 'updateUser';
export const QUICK_REGISTER_USER_ACTION = 'quickRegisterUser';
export const LOGOUT_AND_REDIRECT_ACTION = 'logoutAndRedirect';
export const SET_APP_IS_ACTIVE = 'setAppIsActive';
export const SET_NEW_VERSION_AVAILABLE = 'setNewVersionAvailable';
export const SET_APP_SHOULD_RELOAD = 'setAppShouldReload';
export const SET_SERVICE_WORKER = 'setServiceWorker';
export const CHECK_SERVICE_WORKER_UPDATES = 'checkServiceWorkerUpdates';
export const CLOSE_ALL_MODALS = 'closeAllModals';
export const HAS_TFA_ENABLED_ACTION = 'hasTFAEnabled';
export const GET_TFA_SECRETS_ACTION = 'getTFASecrets';
export const ENABLE_TFA_ACTION = 'enableTFA';
export const DISABLE_TFA_WITH_PASSWORD_ACTION = 'disableTFAWithPassword'
export const DISABLE_TFA_WITH_EMAIL_ACTION = 'disableTFAWithEmail'
export const SEND_TFA_DISABLE_EMAIL_ACTION = 'sendTFADisableEmail'
export const SHOULD_UPDATE_ACTION = 'shouldUpdate';
export const GET_API_VERSION = 'getApiVersion';
export const RECORD_AD_EVENT_ACTION = 'recordAdEvent';
