import * as Sentry from '@sentry/browser';
import { IGNORED_ERRORS_REGEX } from "@/configs";

/**
 * Checks if the exception should be captured or ignored
 * @param Error error
 */
function shouldCaptureException(error) {
  if(error?.graphQLErrors?.[0]?.extensions?.category === 'generic') return false;

  return !IGNORED_ERRORS_REGEX.some( (value) => {
    return value.test(error.message)
  })

}

export const captureException = (error) => {
  if (!shouldCaptureException(error)) {
    return;
  }

  // Capture locally by logging to the console
  if(!process.env.VUE_APP_SENTRY_DSN) {
    // eslint-disable-next-line no-console
    console.error(error);
    return;
  }

  Sentry.captureException(error);
}