import { gql } from '@apollo/client'

export const SEARCH_FRAGMENT = gql`fragment search on Search {
    id
    title
    image
    subtitle
}`

export const FACILITY_FRAGMENT = gql`fragment facility on Facility {
    id
    name
    profile_image
    enabled_subscriptions {
        id
        name
        price
    }
}`

export const ORDERABLE_PIVOT_FRAGMENT = gql`fragment orderablePivot on OrderablePivot {
    id
    price_at_checkout
    refunded_at
}`

export const EVENT_FRAGMENT = gql`fragment event on Event {
    id
    title
    image
    opens_at
    held_at
    attendee_count
    total_capacity
    event_category {
        id
        name
    }
    host_facility {
        id
        name
    }
    organizer {
        id
        name
    }
    tickets {
        id
        quantity_available
        sold_tickets_count
    }
    products_for_sale {
        id
        quantity_available
        sold_orders_count
    }
    cancelled_at
}`;

export const USER_FRAGMENT = gql`fragment user on User {
    id
    first_name
    bikes {
        id
        build_year
        model
        pivot {
            id
            is_default
        }
        bike_make {
            id
            make
        }
    }
    qr_code
    blood_type {
        id
        name
    }
    address
    contact_person_name
    contact_person_tel
    doctor_name
    doctor_tel
    allergies
    federations {
        id
        system_name
        pivot {
            id
            is_default
        }
    }
    location {
        id
        name
    }
    facilities {
        ...facility
    }
    user_checkins {
        id
    }
    sports {
        id
        name
        pivot {
            id
            is_default
        }
    }
    nationality {
        id
        country
    }
    subscriptions {
        id
    }
    has_ehbo
    date_of_birth
    last_name
    license_plate_number
    license_number_federation
    transponder_number
    mobile
    gender
    telephone
    bsn
    start_number
    profile_image
    email
    driver_license_number
    has_tfa
    email_verified_at
}`

export const SINGLE_FACILITY_FRAGMENT = gql`fragment singleFacility on Facility {
    id
    name
    description
    website
    enabled_subscriptions {
        id
        name
        price
    }
    public_subscriptions {
        id
        name
        price
        is_publicly_orderable
        is_live
    }
    phone
    email
    profile_image
    facility_type {
        id
        name
    }
    stripe {
        id
        stripe_user_id
    }
    admins {
        id
        first_name
        last_name
        email
    }
    events {
        id
        title
        image
        text
    }
}`

export const PRODUCT_FAMILY_FRAGMENT = gql`fragment productFamily on ProductFamily {
    id
    name
    description
    
    parent {
        id
        name
    }
}`

export const PRODUCT_FRAGMENT = gql`fragment product on Product {
    id
    facility_id
    name
    description
    product_family_id
    available_in_webshops
    available_in_events
    created_at
    updated_at
    deleted_at
    
    facility {
        id
        name
    }
}`
export const PRODUCT_FRAGMENT_WITH_RELATIONS = gql`fragment productWithRelations on Product {
    ...product

    productFamily {
        ...productFamily
    }

    variants {
        ...productVariant
    }
}`

export const PRODUCT_VARIANT_FRAGMENT = gql`fragment productVariant on ProductVariant {
    id
    product_id
    price
    name
    description
    sort_order
    sku
    in_stock_quantity
    has_unlimited_stock
    created_at
    updated_at
    deleted_at
}`

export const PRODUCT_VARIANT_FRAGMENT_WITH_RELATIONS = gql`fragment productVariantWithRelations on ProductVariant {
    ...productVariant

    product {
        ...product

        productFamily {
            ...productFamily
        }
    }
}`

export const EVENT_PRODUCTS_FOR_SALE = gql`fragment eventProductVariant on EventProductVariant {
    id
    event_id
    
    product_variant_id
    quantity_available
    max_per_order
    requirements
    is_for_members
    is_required

    sold_orders_count

    productVariant {
        ...productVariantWithRelations
    }

    subscriptions {
        id
    }
}`

export const ORDER_FOR_EVENT_FRAGMENT = gql`fragment orderForEvent on Order {
    id
    name
    seller {
        ...on Facility {
            id
            name
        }
        ...on User {
            id
            first_name
        }
    }
    tickets {
        id
        name
        event {
            id
            title
            held_at
        }
        pivot {
            ...orderablePivot
        }
    }
    products {
        id
        productVariant {
            id
            name
        }
        event {
            id
            title
            held_at
        }
        pivot {
            ...orderablePivot
        }
    }
    stripe_status
    rider_fee
    total_price
    created_at
    user_id
}
${ORDERABLE_PIVOT_FRAGMENT}
`