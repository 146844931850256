export const CURRENT_USER_GETTER = 'currentUser';
export const CURRENT_USER_SUBSCRIPTIONS_GETTER = 'currentUserSubscriptions';
export const PENDING_EMAIL_CHANGES_GETTER = 'pendingEmailChanges';
export const GET_EVENT_ID_GETTER = 'getEventId';
export const GET_PLACE_GETTER = 'place';
export const GET_CIRCUIT_PLACE_GETTER = 'circuitPlace';
export const GET_APP_IS_ACTIVE = 'appIsActive';
export const GET_APP_VERSION = 'appVersion';
export const GET_NEW_VERSION_AVAILABLE = 'newVersionAvailable';
export const GET_APP_SHOULD_RELOAD = 'appShouldReload';
export const GET_SERVICE_WORKER = 'serviceWorker';
export const GET_APP_SHOULD_UPDATE = 'appShouldUpdate';
export const OPTIMIZELY_IS_READY = 'optimizelyIsReady';
export const GET_MAINTENANCE_MODE = 'maintenanceMode';