export const SET_CURRENT_USER_MUTATION = 'setCurrentUser';
export const ADD_FACILITY_TO_USER_MUTATION = 'addFacilityToUser';
export const SET_PENDING_EMAIL_CHANGES_MUTATION = 'setPendingEmailChanges';
export const SET_EVENT_ID_MUTATION = 'setCheckinEventId';
export const SET_PLACE_MUTATION = 'setPlace';
export const SET_CIRCUIT_PLACE_MUTATION = 'setCircuitPlace';
export const SET_APP_IS_ACTIVE = 'setAppIsActive';
export const SET_APP_VERSION = 'setAppVersion';
export const SET_NEW_VERSION_AVAILABLE = 'setNewVersionAvailable';
export const SET_APP_SHOULD_RELOAD = 'setAppShouldReload';
export const SET_SERVICE_WORKER = 'setServiceWorker';
export const SET_APP_SHOULD_UPDATE = 'setAppShouldUpdate';
export const SET_OPTIMIZELY_IS_READY = 'setOptimizelyIsReady';
export const SET_API_VERSION = 'setApiVersion';
export const SET_MAINTENANCE_MODE = 'setMaintenanceMode';