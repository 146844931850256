import { SET_EVENT_ID_MUTATION } from "@/store/store-mutations";
import { GET_EVENT_ID_GETTER } from "@/store/store-getters";

export default {
  state: {
    eventId: null
  },
  mutations: {
    [SET_EVENT_ID_MUTATION]: (state, eventId) => {
      state.eventId = eventId;
    },
  },
  getters: {
    [GET_EVENT_ID_GETTER]: state => state.eventId
  }
}
