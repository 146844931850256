export class ValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ValidationError';
    this.errors = {};
  }

  add(key, message) {
    if (!this.errors[key]) {
      this.errors[key] = [];
    }

    this.errors[key].push(message);

    return this;
  }

}