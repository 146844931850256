import store from "@/store";
import { REFRESH_EXPIRED_ACCESS_TOKEN_ACTION } from "@/store/store-actions";

/**
 * This service will check for expired access tokens every 5 minutes
 *
 * @type {{init(): void}}
 */
export const RefreshTokensService = {
  init() {
    setInterval( () => {
      store.dispatch(REFRESH_EXPIRED_ACCESS_TOKEN_ACTION);
    }, 1000 * 60 * 5);
  }
}
