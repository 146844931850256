import { OPTIMIZELY_IS_READY } from "@/store/store-getters";
import { SET_OPTIMIZELY_IS_READY } from "@/store/store-mutations";

export default {
  state: {
    optimizelyIsReady: false,
  },
  getters: {
    [OPTIMIZELY_IS_READY]: state => {
      return state.optimizelyIsReady;
    }
  },
  mutations: {
    [SET_OPTIMIZELY_IS_READY](state) {
      state.optimizelyIsReady = true;
    }
  },
}