import moment from 'moment';
import { CapacitorUpdater} from "@capgo/capacitor-updater";
import store from "@/store";
import { SET_APP_VERSION } from "@/store/store-mutations";
import { version as packageVersion } from '@/../package.json';
import { Capacitor } from "@capacitor/core";
import { captureException } from "@/utils";

export const AppService = {
  async init() {
    moment.locale(window.navigator.userLanguage || window.navigator.language);

    if (Capacitor.isNativePlatform()) {

      try {
        await CapacitorUpdater.notifyAppReady()
      } catch (e) {
        captureException(e)
      }
    }

    await this.getAppVersion();
  },

  async getAppVersion() {
    let appVersion = packageVersion;

    if (Capacitor.isNativePlatform()) {
      try {
        const {bundle} = await CapacitorUpdater.current();
        appVersion = (bundle.id === 'builtin') ? packageVersion : bundle.version;
      } catch (e) {
        captureException(e)
      }
    }

    store.commit(SET_APP_VERSION, appVersion)
  }

};