import Dinero from 'dinero.js';

/**
 * Represents the price as minor-currency-units (cents) in Dinero.
 * @param amount
 * @param currency
 * @returns {*}
 */
export const stripeAmount = (amount, currency = 'EUR') => {
  return Dinero({ amount, currency }).setLocale(navigator.language).toFormat('$0,0.00');
};

/**
 * Represents the price fraction amount (euros) formatted with Dinero (ie. formatted in price * 100)
 *
 * @param amount
 * @param currency
 * @returns {*}
 */
export const priceAmount = (amount, currency = 'EUR') => {
  return Dinero({ amount: Math.round(amount * 100), currency }).setLocale(navigator.language).toFormat('$0,0.00');
};

export const stripeFee = (stripeAmount, paymentMethod) => {
  switch (paymentMethod) {
    case 'card':
      return Math.ceil( (stripeAmount * 0.019) + 35);
    case 'ideal':
      return 35;
    case 'bancontact':
      return 45;
    default:
      return 0;
  }
}
