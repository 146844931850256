/* eslint-disable no-console */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@ionic/core/css/ionic.bundle.css";
import "@/assets/tailwind.css";
import { apolloProvider, AUTH_TOKEN } from "./vue-apollo";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { defineCustomElements as defineWebSocial } from "web-social-share/dist/loader";
import "./registerServiceWorker";

import { registerFilters } from "@/filters";
import { registerPlugins } from "@/plugins";
import { registerComponents } from "@/components";
import { captureException } from "@/utils";
import { App as MotoApp } from "@capacitor/app";
import { FullstoryService } from "./services/fullstory.service";
import { SentryService } from "./services/sentry.service";
import { RefreshTokensService } from "./services/refreshtokens.service";
import { AppService } from "./services/app.service";
import { initiliazeTranslations } from "@/translations";
import { routeNames } from "./router/config";
import Gate from "./policies/Gate";

import { CURRENT_USER_GETTER } from "@/store/store-getters";
import {
  SET_APP_IS_ACTIVE,
  SET_NEW_VERSION_AVAILABLE,
  SET_SERVICE_WORKER,
} from "@/store/store-actions";
import QuickLoginModal from "@/components/modals/QuickLoginModal";

import { SHOULD_UPDATE_ACTION, GET_API_VERSION } from './store/store-actions'
import { getOptimizelyClient } from './services/optimizely.service'

import { Preferences } from "@capacitor/preferences";
import { PushNotificationsService } from "./services/push-notifications.service";
import authStorage from "./utils/authStorage";

// Migrate storage to new format, https://capacitorjs.com/docs/updating/3-0#backward-incompatible-plugin-changes
Preferences.migrate();

// Initialize all global settings.
AppService.init();

SentryService.init();
FullstoryService.init();
RefreshTokensService.init();
PushNotificationsService.init();

registerFilters();
registerPlugins();
registerComponents();

MotoApp.addListener("appUrlOpen", function (data) {
  // Example url: https://beerswift.app/tabs/tabs2
  // slug = /tabs/tabs2
  const slug = data.url.split("motoinside.app").pop();

  // We only push to the route if there is a slug present
  if (slug) {
    router.push({
      path: slug,
    });
  }
});

// Used to keep app up to date
MotoApp.addListener('appStateChange', (state) => {
  if (state.isActive) {
    store.dispatch(GET_API_VERSION).then( () => store.dispatch(SHOULD_UPDATE_ACTION) );
  }

  store.dispatch(SET_APP_IS_ACTIVE, state.isActive);
});

// Add an event listener for app updates
document.addEventListener("serviceWorkerUpdated", () => {
  store.dispatch(SET_NEW_VERSION_AVAILABLE);
});

// Add an event listener for a new service worker registration
document.addEventListener("serviceWorkerRegistered", (e) => {
  store.dispatch(SET_SERVICE_WORKER, e.detail);
});

Vue.config.productionTip = false;

Vue.prototype.$captureException = captureException;
Vue.prototype.$appUrl = process.env.VUE_APP_URL || "https://app.motoinside.app";
Vue.prototype.$apiStorageUrl = process.env.VUE_APP_API_STORAGE_URL || "http://motoinside-headless-cms.test";
Vue.prototype.$isAuth = (redirectUrl = "/") => {
  if (!store.getters[CURRENT_USER_GETTER]) {
    router.push({ name: "login", params: { nextUrl: redirectUrl } });
    return false;
  }

  return true;
};
Vue.prototype.$asyncAuth = async function () {
  const hasToken = await authStorage.getItem(AUTH_TOKEN);

  if (store.getters[CURRENT_USER_GETTER] && hasToken) {
    return true;
  }

  const p = new Promise(async (resolve) => {
    const modal = await this.$ionic.modalController.create({
      component: QuickLoginModal,
      componentProps: {
        parent: this,
        propsData: {
          success: () => {
            resolve(true);
            this.$ionic.modalController.dismiss(modal);
          },
          closeMe: () => {
            this.$ionic.modalController.dismiss(modal);
          },
        },
      },
    });
    modal.onDidDismiss().then(() => resolve(false));
    await modal.present();
  });

  return p;
}
Vue.prototype.$optimizely = getOptimizelyClient();
Vue.prototype.$routeNames = routeNames;
Vue.prototype.$gate = new Gate();

new Vue({
  i18n: initiliazeTranslations(),
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");

defineCustomElements(window);
defineWebSocial(window);
