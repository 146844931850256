export const nl = {
  abort: "Afbreken",
  addadescription: "Een omschrijving toevoegen...",
  addadmin: "Beheerder toevoegen",
  addanoption: "Voeg een optie toe",
  addanothergroup: "Nog een groep toevoegen",
  addanothertrainingticket: "Nog een ticket toevoegen",
  addextrafield: "Extra veld toevoegen",
  addimage: "Afbeelding toevoegen",
  addlocation: "Locatie toevoegen",
  addmember: "Lid toevoegen",
  addmembership: "Lidmaatschap toevoegen",
  addnewfederation: "Nieuwe bond toevoegen",
  addnews: "Nieuws toevoegen",
  addnewsport: "Add new sport",
  addparticipatingevent: "Voeg een deelnemend evenement toe",
  addplace: "Een plaats toevoegen",
  addproductforsale: "Product voor verkoop toevoegen",
  addrequirement: "Vereiste toevoegen",
  address: "Adres",
  addsubscription: "Abonnement aanmaken",
  addtickets: "Tickets toevoegen",
  addticket: "Ticket toevoegen",
  addtimegroup: "Tijdslot toevoegen",
  addtimeslots: "Tijdsloten toevoegen",
  addtimeslot: "Tijdslot toevoegen",
  addtocompetition: "Toevoegen aan competitie",
  admin: "Beheerder",
  admins: "Beheerders",
  advanced: "Gevorderd",
  advertisementdetails: "Advertentie details",
  advertisements: "Advertenties",
  agerequirementsfailed: "Er is niet voldaan aan de leeftijdseisen",
  allergies: "Allergieën",
  alleventsfilter: "Alle evenementen",
  allevents: "Alle evenementen",
  allowedmemberships: "Toegestane lidmaatschappen",
  allpassedevents: "Alle eerdere evenementen",
  allupcomingevents: "Alle aankomende evenementen",
  alreadypurchased: "Al gekocht",
  amount: "Bedrag",
  annualprice: "Jaarlijkse prijs",
  apppoweredby: "Deze app wordt mede mogelijk gemaakt door:",
  aregoingtothisevent: "{numberriders} rijders gaan naar dit evenement",
  areyousure: "Weet je het zeker?",
  areyousurethatyouwanttocancelmanymemberships: "Weet je zeker dat je de lidmaatschappen van ({amount}) leden wilt opzeggen?",
  areyousurethatyouwanttochangemanymemberships: "Weet je zeker dat je {amount} lidmaatschappen wilt bijwerken?",
  areyousureyouwanttorefund: "Weet je zeker dat je deze order wil terug betalen?",
  authenticatorcode: "Authenticator code",
  back: "Terug",
  backtoapp: "Terug naar app",
  backtofacility: "Terug naar de faciliteit",
  backtohome: "Terug naar de startpagina",
  backtologin: "Terug naar inloggen",
  balance: "Balans",
  basicinformationregister: "We vragen deze basisgegevens om het mogelijk te maken voor de faciliteit om je te identificeren en je gebruik te personaliseren.",
  becomeamember: "Lid worden",
  beginner: "Beginner",
  begins_at: "Begint om",
  begintime: "Begintijd",
  bikeinputlabel: "Motor",
  bloodtype: "Bloedgroep",
  brightenscreen: "Maak scherm helder",
  bsnnumber: "BSN nummer",
  bulkeditmembers: "Leden in bulk bewerken",
  bulkupdatemembersamount: "Bulkupdate ({amount}) leden",
  buymembership: "Koop lidmaatschap",
  buyticket: "Koop een ticket",
  buytickets: "Koop tickets",
  buytrainingticket: "Ticket kopen",
  byacceptingthismandate: "Als je dit SEPA mandaat accepteerd dan authoriseer je Moto Inside en Stripe om automatisch af te schrijven van je bankrekening.",
  cameranotsupported: "De camera is niet toegankelijk of wordt niet ondersteund.",
  camerapermissionswarning: "De camera kon niet worden geopend. Controleer de camera permissies.",
  cancel: "Annuleren",
  canceleventaction: "Annuleer evenement",
  canceleventconfirmation: "Weet je zeker dat je dit evenement wilt annuleren. Alle rijders krijgen hun geld terug.",
  canceleventseries: "Evenement serie annuleren",
  canceleventserieswarning: "Dit evenement maakt deel uit van een serie, selecteer alstublieft welke evenementen je wilt annuleren.",
  cancelingeventpleasewait: "Evenement wordt geannuleerd, even geduld aub.",
  cancelingeventseriespleasewait: "Evenementreeks wordt geannuleerd, even geduld a.u.b..",
  cancelledeventsfilter: "Geannuleerde evenementen",
  cancelledevents: "Geannuleerde evenementen",
  cancelledstatus: "Geannuleerd",
  cancelmembership: "Annuleer lidmaatschap",
  cancelmemberships: "Annuleer lidmaatschappen",
  capacitycheckinlabel: "ingecheckt",
  categories: "Categorieën",
  categoryallevents: "Alle evenementen",
  categorytraining: "Training",
  changeemailaddress: "E-mail adres wijzigen",
  changeemailaddressaction: "E-mail adres wijzigen",
  changeinformation: "Persoonlijke informatie bijwerken",
  changeexpirationdate: "Wijzig vervaldatum",
  changemember: "Lid wijzigen",
  changephoto: "Foto wijzigen",
  changesubscription: "Wijzig abonnement",
  checkedinriders: "Ingecheckte rijders ({number})",
  checkin: "Inchecken",
  checkinnewrider: "Nieuwe rijder inchecken",
  checkinsonthisdevice: "Check-ins op dit apparaat: ",
  checkyouremail: "We hebben je zojuist een E-mail gestuurd, controleer je inbox, en ook je spam-filter.",
  chooseacategory: "Kies een categorie",
  chooseaprofilepicture: "Kies een profielfoto",
  chooseatype: "Kies een type",
  chooseaweek: "Kies een week",
  choosefederation: "Kies bond",
  chooselevel: "Kies niveau",
  choosemembership: "Kies lidmaatschap",
  choosemembershipyouwanttoaddmembersto: "Kies een lidmaatschap waar je leden voor toe wilt voegen.",
  chooseproducts: "Kies een product",
  choosesport: "Kies sport",
  choosetrainingticket: "Kies ticket",
  choosetype: "Kies type",
  choosewhen: "Kies wanneer",
  clear: "Wissen",
  clickoncreateacceptterms: "Als je op aanmaken drukt ga je akkoord met de algemene & privacy voorwaarden.",
  clicks: "Clicks",
  clicktoselectmember: "Klik hier om een rijder te kiezen...",
  close: "Sluiten",
  club: "Club",
  commission: "Commissie",
  commissions: "Commissies",
  competition: "Competitie",
  competitionhasnoplannedevents: "Deze wedstrijd heeft geen geplande evenementen",
  competitionhasnoproductsforsale: "Deze wedstrijd heeft op dit moment geen producten te koop",
  competitions: "Competities",
  competitionstatus: "Competitie",
  competitiontickets: "Competitie tickets",
  concept: "Concept",
  confirm: "Bevestigen",
  confirmemail: "Bevestig e-mail",
  connecttostripe: "Koppelen met Stripe",
  continue: "Volgende",
  copylink: "Kopieer link",
  count: "Aantal",
  createanaccount: "Een account aanmaken",
  createclubdealerorshop: "Maak jouw club, dealer of winkel aan en promoot het aan rijders.",
  createcompetition: "Creëer een competitie",
  createcompetitiondescription: "Is een reeks van verbonden evenementen.",
  createevent: "Evenement aanmaken",
  createeventdescription: "Een evenement wordt gebruikt wanneer u een of meer evenementen wilt hosten zonder verbinding",
  createfacility: "Faciliteit aanmaken",
  create: "Maken",
  createnewpost: "Nieuw bericht maken",
  createplacesearch: "{place} aanmaken",
  createtrack: "Baan aanmaken",
  creditcard: "Creditcard",
  customquantity: "Handmatig aantal",
  dateofbirth: "Geboortedatum",
  deletecheckin: "Check-in verwijderen",
  deletedstatus: "Verwijderd",
  depositedon: "Gestort op",
  description: "Omschrijving",
  descriptionsaved: "Omschrijving opgeslagen",
  deselectall: "Selectie wissen",
  detailsofregistration: "Details van inschrijving",
  disabletfa: "Two-factor verificatie uitzetten",
  disabletfawithpasswordinstructions: "Om two-factor verificatie uit te zetten moet je zowel\n" +
    " de huidige authenticator code invoegen als ook je wachtwoord.",
  disabletfawithemailinstructions: "Klik op de knop om een E-mail te versturen om two-factor verificatie " +
    " uit te zetten. Deze mail bevat een disable-code. Geef vervolgens je wachtwoord én de disable code in," +
    " in de onderstaande velden.",
  doctorinformation: "Informatie huisarts",
  downloadexport: "Download export",
  driverlicensenumber: "Rijbewijsnummer",
  duplicateevent: "Dupliceer evenement",
  duplicateeventconfirmation: "Weet u zeker dat u dit evenement wilt dupliceren?",
  duplicatingeventpleasewait: "Het evenement wordt gedupliceerd. Even geduld aub.",
  dusty: "Stoffend",
  editadmin: "Beheerder wijzigen",
  editevent: "Evenement wijzigen",
  editfacility: "Wijzig facility",
  editmembership: "Lidmaatschap wijzigen",
  editmembershipaction: "Lidmaatschap wijzigen",
  editoptions: "Opties bewerken",
  email: "E-mail",
  emailchangeconfirmed: "Je E-mail adres is gewijzigd",
  emailisnotverifiedinfo: "Je e-mail is nog niet geverifieerd, volg de link hieronder om de verificatie mail nogmaals te versturen.",
  emergencyinformation: "Informatie in noodgevallen",
  enable: "Inschakelen",
  enabletfa: "Two-factor verificatie inschakelen",
  enabletfainstructions: "Om Two-factor verificatie in te schakelen, gebruik een Authenticator app om de QR-code te scannen, bijvoorbeeld met Google Authenticator. <br/><br/>" +
    "Bewaar de veiligheidssleutel, voor het geval dat je je Authenticator vervangt of verliest. <br/> <br/>" +
    "Nadat je de code in de authenticator hebt gescant, vul de 6-cijferige code van de authenticator in onderstaand veld in.<br/><br/>" +
    "Vul vervolgens je wachtwoord nogmaals in ter controle.",
  ends_at: "Eindigt om",
  endtime: "Eindtijd",
  endtimeconstrained: "De eindtijd is ge-reset door een wijziging in de start tijd",
  enduro: "Enduro",
  enteradescription: "Geef een beschrijving op",
  enteravalue: "Voer iets in",
  enteremailtocontinue: "Vul je E-mail adres in om door te gaan",
  entermailorusernametoinviteclub: "Vul een e-mail of gebruikersnaam in om rijders uitnodigen voor je club.",
  enteronetimepasswordaction: "Bevestigen",
  enterthenameofthefield: "Geef dit veld een naam",
  enteryourdetailstocontinue: "Vul je gegevens aan om door te gaan",
  errorratelimited: "Onze servers zijn momenteel bezet. Probeer het later opnieuw.",
  eventalreadygiven: "Evenement is al afgelopen",
  event: "Evenement",
  eventname: "Evenementnaam",
  events: "Evenementen",
  eventsfilterintervalhalfyear: "Komend half jaar",
  eventsfilterintervalmonth: "Deze maand",
  eventsfilterintervaltoday: "Vandaag",
  eventsfilterintervaltomorrow: "Morgen",
  eventsfilterintervalweek: "Deze week",
  eventsfilterintervalyear: "Komend jaar",
  eventsincompetition: "Evenementen in deze competitie",
  eventtypesselected: "{length} evenementtypes geselecteerd",
  everyweek: "Elke week",
  everything: "Alles",
  exampleticketname: "Voorbeeld: jeugdbaan of volwassenen",
  expiredat: "Vervallen op",
  expiresat: "Vervalt op",
  exporteventcheckins: "Exporteer check-ins",
  exporteventordersaction: "Exporteer betalingen",
  exporteventregistrationsaction: "Exporteer inschrijvingen",
  exportmembershipsaction: "Exporteer leden",
  exportready: "De export is klaar",
  exporttransfersaction: "Exporteer transfers",
  extrafields: "Extra velden",
  extrainformation: "Extra informatie",
  facilities: "Faciliteiten",
  facilitiesnearby: "Facilitieten in de buurt van {town}",
  federations: "Bonden",
  federationsselected: "{length} bonden geselecteerd",
  fee: "Vergoeding",
  fees: "Vergoedingen",
  female: "Vrouw",
  fieldisrequired: "Veld is verplicht",
  fillinnewpassword: "Vul je nieuwe wachtwoord in.",
  filtereventsby: "Filter evenementen op",
  filters: "Filters",
  firstaidpresent: "EHBO aanwezig?",
  firstname: "Voornaam",
  forceupdatetitle: "Update de app",
  forceupdatemessage: "Om deze app te kunnen blijven gebruiken, moet je de app bijwerken. Ga naar de App Store of Google Play Store om de app bij te werken.",
  forgotpassword: "Wachtwoord vergeten?",
  forwhichlevelmotorcross: "Voor welk niveau motorcrossrijder?",
  freetraining: "Training",
  from: "Van",
  fullmembership: "Volledig lidmaatschap",
  gender: "Geslacht",
  grasstrack: "Grasbaan",
  groomed: "Losgetrokken",
  groups: "Groepen",
  hasehbo: "Heeft EHBO",
  hasehboinputlabel: "Heeft EHBO?",
  heldat: "Gehouden op",
  heldeventsfilter: "Gehouden evenementen",
  heldevents: "Gehouden evenementen",
  hiddencount: "{count} verborgen",
  hostedby: "Gehost door",
  hostfacility: "Hostfaciliteit",
  iban: "IBAN",
  ideal: "iDEAL",
  impressions: "Impressies",
  infoabbrevation: "Info",
  info: "Informatie",
  instock: "voorraad",
  interactions: "Interacties",
  islive: "Is live?",
  isonlyformembersquestion: "Is alleen voor leden?",
  itemisrequiredquestion: "Item is verplicht?",
  knowledge: "Kennis",
  label: "Label",
  lastname: "Achternaam",
  licensedfederationrequired: "Licentie van bond verplicht?",
  licensenumberfederation: "Licentienummer",
  licenseplatenumber: "Nummer van kenteken",
  lineitems: "Regelitems",
  linktostripebuy: "Om tickets, producten of andere diensten te verkopen als {facility} moet je je koppelen met Stripe",
  loadingmore: "Meer aan het laden...",
  location: "Locatie",
  login: "Inloggen",
  loginas: "Inloggen als",
  loginwith: "Inloggen met",
  loginwithadifferentemail: "Inloggen met een ander email adres",
  logout: "Uitloggen",
  lostauthenticator: "Ik kan mijn authenticator momenteel niet gebruiken.",
  maintenancemodemessage: "We voeren momenteel gepland onderhoud uit op onze servers. Sluit de app en probeer het over enkele minuten opnieuw.",
  maintenancemodetitle: "We zijn zo terug!",
  make: "Merk",
  male: "Man",
  manage: "Beheer",
  maxcapacityleaveblankformaxcapacity: "Maximale capaciteit (laat leeg voor onbeperkte capaciteit)",
  maxproductsperorder: "Maximum per order",
  maxcount: "Maximum aantal",
  maximumage: "Maximale leeftijd (inclusief)",
  maximumcountunlimited: "Maximum aantal (0 is onbeperkt)",
  maxticketsperticket: "Maximum aantal per order",
  medicalacknowledgement_html: "" +
    "<strong>Medische verklaring</strong><br/><br/>" +
    "Ik verklaar dat ik in stem en kennis heb genomen van de volgende drie medische punten:<br/><br/>" +
    "1. Ik verklaar dat ik geestelijk en lichamelijk geschikt bent om motorsport te beoefenen.<br/>" +
    "2. Ik verklaar dat ik geen geneesmiddelen, en/of drugs/doping of alcoholische dranken zal gebruiken die de rijvaardigheid in gevaar brengen.<br/>" +
    "3. Ik verklaar akkoord te zijn met vorenstaande medische verklaring.<br/><br/>" +
    "Bij twijfel neem contact op met het Bondskantoor.",
  membershiprequired: "Lidmaatschap verplicht?",
  memberships: "Lidmaatschappen",
  membershipscancelledamount: "{amount} Lidmaatschappen geannuleerd",
  membershipsuccessfull: "Jouw lidmaatschap is succesvol verwerkt.",
  membershipsupdatedamount: "{amount} Lidmaatschappen bijgewerkt",
  members: "Leden",
  message: "Melding",
  minimumage: "Minimum leeftijd",
  mobilenumber: "Mobiel nummer",
  model: "Model",
  mondisclaimer: "MON Vrijwaringsverklaring",
  mondisclaimer_html: "" +
    "<strong>Vrijwaringsverklaring (Exoneratieclausule)</strong><br/>" +
    "1. Gevaarlijke sport<br/>" +
    "Motorsport is een gevaarlijke sport met een verhoogd risico op letsel- en zaakschade.<br/><br/>" +
    "2. Aansprakelijkheid MON c.s. uitgesloten<br/>" +
    "Aansprakelijkheid van MON en de aangesloten verenigingen en haar leden, medewerkers en vrijwilligers (hierna: ’MON c.s.’) voor schade als gevolg van (motorsport)ongevallen is uitdrukkelijk uitgesloten, behoudens opzet of bewuste roekeloosheid. U zal MON c.s. niet voor deze schade aansprakelijk stellen.<br/>" +
    "<br/>" +
    "3. Aansprakelijkheidsrisico MON c.s. niet verzekerbaar<br/>" +
    "De reden voor deze uitsluiting van aansprakelijkheid is dat het aansprakelijkheidsrisico van MON c.s. niet verzekerbaar is. Indien MON c.s. succesvol aansprakelijk zou kunnen worden gesteld, zou de georganiseerde beoefening van motorsport en daarmee het voortbestaan van MON en de aangesloten verenigingen onmogelijk worden.<br/>" +
    "<br/>" +
    "4a. PO-dekking (<strong>enkel van toepassing als gekozen is voor WA daglicentie</strong>)<br/>" +
    "Een PO (Persoonlijke Ongevallendekking) dekking is er alléén voor de MON-startbewijshouders en voor rijders met een OTR- bewijs. Met deze WA daglicentie komt u niet in aanmerking voor de genoemde PO-dekking." +
    "<br/><br/>" +
    "4b. Beperkte PO-dekking (<strong>enkel van toepassing als gekozen is voor WA + PO daglicentie</strong>)<br/>" +
    "De standaarddekking van de afgesloten daglicentie bedraagt bij blijvende invaliditeit maximaal € 25.000,- en bij overlijden € 5.000,-." +
    "<br/><br/>" +
    "5. Voorlichtingsfilm<br/>" +
    "Om u te wijzen op de risico’s waarmee motorsport gepaard gaat, heeft MON een voorlichtingsvideo laten maken. In deze korte film wordt onder meer uitgelegd wat u kunt doen om letsel te voorkomen en wat uw verantwoordelijkheden en die van MON zijn. Iedereen die een motorcrosslicentie of -daglicentie aanvraagt, dient kennis te hebben genomen van de inhoud van de video De mooie en mindere kanten van motorcross, die is te raadplegen via de MON-website (www.mon.nl/licentie-aanvragen/)<br/>" +
    "<br/>" +
    "Alles over de verzekering kunt u lezen in onze verzekeringsbrochure. Deze is te vinden op de pagina: https://mon.nl/licentie-aanvragen." +
    "Heeft u nog vragen? Dan kunt u zich richten tot het bondsbureau van MON via info@mon.nl of 0485-315080.",
  mondisclaimeracknowledgement_html: "" +
    "Ik verklaar dat ik kennis hebt genomen van het voorgaande en ik ben bewust van:<br/>" +
    "1. De risico’s van de beoefening van de motorsport.<br/>" +
    "2. De uitsluiting van de aansprakelijkheid van MON c.s.<br/>" +
    "3. De beperkte dekking van deze daglicentie en de mogelijkheid tot het bijverzekeren tegen betaling;",
  motocross: "Motorcross",
  motoinsideappname: "MotoInside",
  muddy: "Modderig",
  multiselect: "Meerdere selectie",
  namecontactperson: "Naam contactpersoon",
  namefamilydoctor: "Naam huisarts",
  name: "Naam",
  nationality: "Nationaliteit",
  nettotal: "Netto totaal",
  never: "Nooit",
  newemailaddress: "Nieuw E-mail adres",
  newoption: "Nieuwe optie",
  newpost: "Nieuw bericht",
  news: "Nieuws",
  newstype: "Type nieuws",
  newticket: "Nieuw ticket",
  newtimeslot: "Nieuw tijdslot",
  newverificationmailsendsuccess: "Een nieuwe verificatiemail is verzonden naar {email} je hebt 1 uur om je account te verifiëren.",
  next: "Volgende",
  no: "Nee",
  noadvertisements: "Geen advertenties lopen er voor deze faciliteit. Voorlopig is het enkel mogelijk om advertentie mogelijkheid aan te vragen bij ons: info@motoinside.app",
  nodescription: "Geen beschrijving opgegeven",
  noeventregistrationsyet: "Er zijn nog geen inschrijvingen voor dit evenement",
  noeventsfound: "Geen evenementen gevonden in je gebied. Pas hierboven het bereik aan.",
  noeventstocheckin: "Geen evenementen vandaag om in te checken.",
  nofacilitiesfound: "Geen faciliteiten gevonden in jouw omgeving. Pas het bereik aan.",
  nofacilitymembersfound: "Deze faciliteit heeft geen actieve leden.",
  nofacilitysubscriptionsfound: "Deze faciliteit heeft geen abonnementen.",
  noinformationadded: "Er is geen contactinformatie toegevoegd aan je faciliteit. Voeg contactinformatie zodat gebruikers je club makkelijker kunnen contacteren.",
  nomembermotoinside: "Het lijkt erop dat deze persoon nog geen account heeft op Moto Inside. Voer een e-mail in om hem uit te nodigen.",
  nomemberslabel: "Er zijn geen leden gevonden",
  nolineitemsfound: "Geen regelitems gevonden",
  notransfersfound: "Geen transfers gevonden",
  nonewsfound: "Geen nieuws gevonden. Maak een nieuw bericht.",
  noordersfound: "Je hebt nog geen bestellingen gedaan. Koop tickets of andere producten.",
  nopassedeventslabel: "Deze faciliteit heeft geen eerdere evenementen",
  noproductsfound: "Geen producten gevonden",
  noriderscheckedinyet: "Er zijn nog geen rijders ingecheckt. Check rijders in door ze te scannen.",
  nosubscriptionsfound: "Geen abonnementen gevonden.",
  noticketsavailable: "Geen tickets beschikbaar",
  notmanagingfacilities: "Je beheerd nog geen faciliteiten.",
  notracksarelinked: "Geen banen gekoppeld aan deze club. Maak een baan aan.",
  notyetinvited: "Nog niet uitgenodigd",
  noupcomingeventslabel: "Deze faciliteit heeft geen aankomende evenementen.",
  numberofpendingrefunds: "Aantal openstaande terugbetalingen",
  onetimepassword: "One-time password",
  ok: "Ok",
  onlyfutureevents: "Toekomstige evenementen",
  onlymembers: "Alleen leden",
  onlythisevent: "Alleen dit evenement",
  openappstore: "Open de Apple App Store",
  openplaystore: "Open de Google Play Store",
  openstatus: "Open",
  openstreetmapattribution: "Search data is powered by © OpenStreetMap contributors available under the Open Database License",
  opentime: "Verkoop start op",
  opentimeconstrained: "Ticket verkoop tijd is ge-reset door een wijziging in de event start tijd",
  option: "Optie",
  optional: "Optioneel",
  orderconfirmation: "Bestelling geslaagd",
  orderedat: "Besteld op",
  orders: "Bestellingen",
  organizer: "Organisator",
  organizingfacility: "Kies organiserende faciliteit",
  pagenotauthorized: "Je hebt geen toegang tot deze pagina.",
  pagenotfound: "Pagina niet gevonden",
  paidstatus: "Betaald",
  partiallyrefundedstatus: "Gedeeltelijk terugbetaald",
  participatingevents: "Deelnemende evenementen",
  passedeventstitle: "Eerdere evenementen",
  passwordconfirmation: "Wachtwoord bevestigen",
  password: "Wachtwoord",
  pay: "€ {total} afrekenen",
  paycash: "Betaal contant",
  paycashatevent: "Geen online ticket verkoop. Betaal contant op het evenement",
  paymentoverview: 'Betalingsoverzicht',
  paymentmethod: 'Betaalmethode',
  paymentmethodbancontact: "Bancontact",
  paymentmethodcard: "Kaart / Apple Pay / Google Pay",
  paymentmethodideal: "iDeal",
  paymentmethodgiropay: "Giropay",
  paymentnotavailable: "Betalingen zijn niet beschikbaar",
  payments: "Betalingen",
  paymentsurcharge: "Servicekosten",
  payouts: "Uitbetalingen",
  payticket: "{price} afrekenen",
  pendingemailchangerequests: "Bestaande verzoeken om het E-mail te wijzigen",
  pendingrefundsamount: "Bedrag aan openstaande terugbetalingen",
  personalqrcode: "Persoonlijke QR-code",
  peryear: "per jaar",
  phonecontactperson: "Telefoon contactpersson",
  phonefamilydoctor: "Telefoon huisarts",
  phonenumber: "Telefoonnummer",
  phone: "Telefoon",
  pickanoption: "Kies een optie",
  plannedeventsfilter: "Geplande evenementen",
  plannedevents: "Geplande evenementen",
  popoveractions: "Acties",
  preferredstartnumber: "Voorkeur startnummer",
  prepped: "Geprepareerd",
  price: "Prijs",
  privacyacknowledgement_html: "" +
    "<strong>Privacy verklaring</strong><br/><br/>" +
    "Ik verklaar akkoord te zijn met de volgende privacy verklaring:<br/>" +
    "1. Ik geef toestemming om de persoonsgegevens te verwerken binnen de wettelijke termijn die daarvoor geldt.<br/>" +
    "2. Ik geef toestemming voor het gebruik van foto- en beeldmateriaal voor publicatie t.b.v. MON.<br/>" +
    "3. Ik verklaar akkoord te zijn met vorenstaande privacy verklaring.",
  privacypolicy: "Je gaat akkoord met de {0} als je op Registreren klikt.",
  privacypolicylabel: "privacy voorwaarden",
  privacypolicyquickregister: "Je gaat akkoord met de {0} als je op bevestigen klikt.",
  productisrequiredonceforevent: "Item is eenmalig verplicht voor dit evenement",
  productquantityavailable: "Aantal beschikbaar",
  products: "Producten",
  productsforsale: "Producten te koop",
  productvariants: "Product varianten",
  professional: "Professioneel",
  profile: "Profiel",
  profilesaved: "Profielinformatie is opgeslagen",
  publish: "Publiceren",
  pulltorefresh: "Sleep naar beneden om te vernieuwen",
  qrcode: "QR-code",
  quads: "Quads",
  recreational: "Amateur",
  refreshing: "Vernieuwen...",
  refunded: "Terugbetaald",
  refundedstatus: "Teruggestort",
  refundedsuccessfully: "De terugstorting is voltooid",
  refundinfo: "Restituties zijn beschikbaar voor betaalde bestellingen binnen 180 dagen na aankoop",
  refundingpleasewait: "Terugbetaling in behandeling, even geduld.",
  refunds: "Restituties",
  register: "Registreren",
  registeredat: "Geregistreerd op",
  registrationform: "Registratieformulier",
  registrations: "Inschrijvingen",
  removesubscription: "Verwijder abonnement",
  remove: "Verwijderen",
  repeat: "Herhalen",
  repeatingendsafter: "Herhalen stopt na",
  repeatnewpassword: "Herhaal / bevestig nieuw wachtwoord.",
  requestpasswordresetlabel: "Vul je e-mail hieronder in om je wachtwoord te resetten",
  requestpasswordreset: "Nieuw wachtwoord aanvragen",
  requestpasswordresetsuccess: "Een e-mail met een wachtwoord reset knop is verzonden naar {email}",
  required: "Verplicht",
  requirements: "Vereisten",
  resale: "Doorverkoop",
  resales: "Doorverkopen",
  resendverificationmail: "Verificatiemail opnieuw versturen",
  resetpasswordsuccesfull: "Je wachtwoord is succesvol gereset. We hebben je ingelogd met je nieuwe inloggegevens.",
  resetyourpassword: "Reset je wachtwoord",
  ridercheckedin: "Rijder is ingecheckt",
  ridernotfoundqrcodeproblem: "Rijder niet gevonden. QR-code werkt niet",
  rough: "Zwaar",
  rutted: "Veel sporen",
  sale: "Verkoop",
  salenotstarted: "Verkoop nog niet gestart",
  sales: "Verkopen",
  salestartsin: "Verkoop begint over {duration}",
  save: "Opslaan",
  savechanges: "Wijzigingen opslaan",
  search: "Zoeken",
  searchalocation: "Zoek een locatie...",
  searchcreate: "{create} aanmaken...",
  searchforadmins: "Zoek naar beheerders...",
  searchforplaces: "Zoek naar plaatsen...",
  searchglobalplaceholder: "Zoek naar evenementen, faciliteiten, nieuws...",
  searchinvalidinput: 'Ongeldige invoer. Alleen alfanumerieke tekens en spaties zijn toegestaan.',
  searchresults: "Zoekresultaten",
  searchrecentsearches: "Recent gezocht",
  searchnorecentsearches: 'Nog geen recente zoekopdrachten, begin met zoeken en log in om dit te zien.',
  searchnoresultfound: 'Geen zoekresultaten gevonden',
  searchtaball: 'Alle',
  searchtabevents: 'Evenementen',
  searchtabfacilities: 'Faciliteiten',
  searchtabnews: 'Nieuws',
  searchuser: "Zoek rijder",
  select: "Selecteer",
  selectall: "Selecteer alles",
  selectbelowwhatdata: "Selecteer hieronder welke gegevens je wilt vragen van een rijder",
  selectedmember: "Geselecteerd lid",
  selectedtoinvite: "Selecteer om uit te nodigen",
  selectevents: "Selecteer evenementen",
  selecteventtype: "Maak een evenement aan",
  selecteditemscount: "{count} Items geselecteerd",
  selecthostfacility: "Selecteer een hostfaciliteit",
  selecthostfacilityifotherthenorganizer: "Selecteer een hostfaciliteit (indien anders dan organisator)",
  selectionlimitreached: "U heeft de limiet van ({count}) selecteerbare items bereikt",
  selectoneofyourbikes: "Selecteer een van je motoren",
  selectorganizer: "Selecteer organisator",
  selectproductsforevent: "Selecteer producten voor dit evenement",
  selecttheitemsyouwanttorefund: "Selecteer de items die je van elk evenement wilt terugbetalen.",
  seller: "Verkoper",
  sellingtrainingtickets: "Verkoop van tickets?",
  selectafacilityfirst: "Selecteer eerst een faciliteit",
  selectapaymentmethod: "Selecteer een betaalmethode",
  sendinvitations: "Uitnodigingen versturen",
  sendtfadisableemail: "Stuur een code om two-factor verificatie uit te zetten naar mijn E-mail.",
  seperatedintimegroups: "Verdeeld in tijdsloten/groepen?",
  servicecosts: 'Service kosten & applicatie fees worden niet teruggestort door de faciliteit bij annulering.',
  share: "Delen",
  sharethispost: "Deel dit bericht",
  shop: "Winkel",
  showall: "Alles weergeven",
  showless: "Minder weergeven",
  showmore: "Meer weergeven",
  sidecars: "Zijspannen",
  soldby: "Verkoop door {facility_name}",
  soldout: "Uitverkocht",
  soldoutstatus: "Uitverkocht",
  soldtickets: "Verkochte tickets",
  soldproducts: "Verkochte producten",
  sortby: "Sorteren op",
  sortbyemail: "E-mail",
  sortbyexpiresat: "Expiratiedatum",
  sortbyname: "Naam",
  sortbysubscribedat: "Inschrijfdatum",
  sortbysubscription: "Abonnement",
  state: "Status",
  stripeverificationmessage: "Als u op onderstaande knop klikt wordt u naar betaalprovider Stripe doorverwezen. Om uw faciliteit te activeren & verifieren is het verplicht om te koppelen met Stripe, zonder deze stap zal uw faciliteit weer verwijderd worden.",
  submit: "Versturen",
  subscription: "Abonnement",
  subscriptionfirstbefore: "Om leden toe te kunnen voegen moet je eerst lidmaatschappen aanmaken.",
  subscriptionremovedsuccessfully: "Abonnement is succesvol verwijderd",
  subscriptions: "Abonnementen",
  suitablefor: "Geschikt voor",
  supermoto: "Supermotard",
  switchevent: "Wissel evenement",
  text: "Tekst",
  tfadisablecode: "Two-factor disable code",
  tfaisdisabled: "Two-factor verificatie is uitgeschakeld",
  tfaisdisabledinformation: "Two-factor verificatie is uitgeschakeld op je account. Schakel het in om je profiel extra te beveiligen.",
  tfaisenabled: "Two-factor verificatie is ingeschakeld",
  tfaisenabledinformation: "Two-factor verificatie is ingeschakeld op je account.",
  thanksforpurchasemessage: "" +
    "<p>Je kunt je QR code nu vinden onder de knop INCHECKEN (rechtsboven op deze pagina). <br/>" +
    "Deze QR code heb je nodig om in te checken op het evenement.</p>" +
    "<p>Om de details van je aankoop te bekijken kun je in je pofiel klikken op ‘Bestellingen’.</p>" +
    "<p>Nog vragen? Stuur ons dan een email via {contactmail} of bel {contactphone}</p>",
  thanksforpurchasemessage_unverified: "" +
    "<p>Je kunt je QR code nu vinden onder de knop INCHECKEN (rechtsboven op deze pagina). <br/>" +
    "Deze QR code heb je nodig om in te checken op het evenement.</p>" +
    "<p>Wil je later terug komen om je QR code te gebruiken? Maak dan een account aan. <br/>" +
    "Je doet dit eenvoudig door de e-mail die je zojuist van ons hebt gehad te verifiëren en hierna een wachtwoord in te stellen.</p>" +
    "<p>Om de details van je aankoop te bekijken kun je in je pofiel klikken op ‘Bestellingen’.</p>" +
    "<p>Nog vragen? Stuur ons dan een email via {contactmail} of bel {contactphone}</p>",
  thanksfortopupmessage: "<p>Je Opwaardering is ontvangen. <br/>" +
    "Klik op de onderstaande knop om terug te keren naar de faciliteit.</p>" +
    "<p>Open het tabblad <strong>Opwaarderingen</strong> in de faciliteit om de status van de balans te bekijken.</p>" +
    "<p>Heeft u vragen? Stuur ons een e-mail op {contactmail} of bel {contactphone}</p>",
  thanksregistration: "Bedankt voor je registratie. We hebben je een activatie e-mail gestuurd om je account te bevestigen. Je hebt een uur de tijd om je account te bevestigen (Controleer ook je spam inbox)",
  ticketfor: "Ticket voor",
  ticketisrequired: "Ticket is verplicht",
  ticketisrequiredonceforevent: "Ticket is eenmalig verplicht voor dit evenement.",
  ticketisseperatedingroups: "Ticket is verdeeld in {number} groep(en)",
  ticketname: "Ticketnaam",
  tickets: "Tickets",
  ticketsforevents: "Tickets voor evenementen",
  till: "Tot",
  time: "Tijd",
  title: "Titel",
  titlepost: "Titel bericht",
  tocheckout: "Afrekenen",
  tocreate: "Aanmaken",
  toemail: "E-mail",
  toexportattendees: "Exporteer deelnemers",
  topay: "Betalen",
  topaywithmethod: "Betalen met {method}",
  topup: "Opwaardering",
  topupconfirmation: "Opwaardering bevestiging",
  topupdefaultdescription: "Opwaardering Moto Inside",
  topupinformation: "<p>Vul je Connect Account aan door geld van uw bank naar uw account over te maken.</p>" +
    "<p>Om de financiering van uw Account te ondersteunen, worden de kosten van de Stripe provider in rekening gebracht," +
    " er worden geen andere kosten in rekening gebracht.</p>",
  topups: "Opwaarderingen",
  torefund: "Terug betalen",
  totalcapacity: "Totale capaciteit (0 is onbeperkt)",
  totalprice: "Totale orderprijs: {price}",
  total: "Totaal",
  tracks: "Banen",
  trackstatus: "Baanconditie",
  trainer: "Trainer",
  trainerpresent: "Trainer aanwezig?",
  trainingevents: "Trainingsevenementen",
  trainingmembership: "Trainingslidmaatschap",
  trainingtickets: "Tickets",
  transactionfee: "Transactiekosten",
  transfers: "Overboekingen",
  transferredon: "Overgedragen op",
  transferdetails: "Transferdetails",
  transpondernumber: "Transpondernummer",
  trial: "Trial",
  twofactorauthentication: "Two-factor verificatie",
  typemembership: "Type lidmaatschap",
  type: "Type",
  upcomingeventstitle: "Aankomende evenementen",
  update: "Bijwerken",
  updateinformation: "Contactinformatie bijwerken",
  uploadportrait: "Upload een profielfoto",
  useavailablestock: "Gebruik actuele voorraad",
  userhasnotpaid: "Rijder heeft nog niet betaald",
  userhaspaid: "Gebruiker heeft betaald",
  validation_required: "{attribute} is verplicht.",
  value: "Waarde",
  verificationmailexpired: "De verificatiemail is verlopen of werkt niet. Vul hieronder je e-mail in en vraag een nieuwe aan.",
  verificationemailreceived: "Je hebt een verificatie E-mail ontvangen met een one-time password, vul het one-time-password hieronder in.",
  verificationemailsent: "We hebben een verificatie E-mail verstuurd met een one-time-password.",
  verificationsuccessful: "Je e-mailadres is succesvol geverifieerd. Bedankt voor uw registratie bij {motoinsideappname}.",
  viewmoredetails: "Meer informatie bekijken",
  watered: "Gesproeid",
  website: "Website",
  whatdatadoyouwanttocollect: "Welke gegevens wil je vragen aan de rijder?",
  whattocheckin: "Kies het type evenement waar je voor wilt gaan inchecken.",
  whichbike: "Welke motor",
  whichfederationcollectlabel: "Welke bond",
  whichfederation: "Welke bond?",
  whichmembershipsarevalid: "Welke lidmaatschappen zijn geldig?",
  whichsport: "Welke sport",
  withdrawnon: "Opgenomen op",
  withinkm: "Binnen {km} km",
  writeapost: "Schrijf een bericht...",
  yearofbuild: "Bouwjaar",
  yearsold: "{age} jaar oud",
  yes: "Ja",
  youhavedisabledgeolocation: "Je hebt je locatie/GPS uitgeschakeld op je apparaat, zet je GPS aan voor de beste ervaring",
  yourdetails: "Jouw gegevens",
  youreventtickets: "Jouw evenement tickets",
  yourfederations: "Jouw bonden",
  yoursports: "Jouw sporten",
}
