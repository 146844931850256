import { SET_CIRCUIT_PLACE_MUTATION, SET_PLACE_MUTATION } from "@/store/store-mutations";
import { GET_CIRCUIT_PLACE_GETTER, GET_PLACE_GETTER } from "@/store/store-getters";

export default {
  state: {
    place: null,
    circuitPlace: null
  },
  mutations: {
    [SET_PLACE_MUTATION](state, place) {
      state.place = place;
    },
    [SET_CIRCUIT_PLACE_MUTATION](state, place) {
      state.circuitPlace = place;
    },
  },
  getters: {
    [GET_PLACE_GETTER]: state => {
      return state.place
    },
    [GET_CIRCUIT_PLACE_GETTER]: state => {
      return state.circuitPlace
    }
  }
}
