import store from "@/store";
import {routeNames} from "@/router/config";
import { CURRENT_USER_GETTER } from "@/store/store-getters";

/**
 * Auth middleware handler
 * Ensures that we have a currentUser, or else we redirect to the login route
 */
class Auth {

    handle({ to }) {

        if (!store.getters[CURRENT_USER_GETTER]) {
            return {
                name: routeNames.LOGIN,
                params: {nextUrl: to.fullPath}
            }
        }
    }

}

export default new Auth()
